import EndPoint from "../../../task/endpoint/Endpoint";
import axios from "axios";
import {ResetPasswordRequestData} from "../infraestructure/ws/ResetPasswordRequestDto";
import i18n from "i18next";
import {Endpoints} from "../../../task/endpoint/EndPoints";
import {ResetPasswordResponse} from "../infraestructure/ws/ResetPasswordResponseDto";

class ResetPasswordRepository {

    private static INSTANCE = new ResetPasswordRepository();

    private constructor() {
    }

    public static getInstance() {
        return ResetPasswordRepository.INSTANCE;
    }

    public async fetchResetPassword(data: ResetPasswordRequestData): Promise<ResetPasswordResponse> {
        const language = i18n.language.split('-')[0].toLowerCase();
        const endPoint = EndPoint.getEndPoint(Endpoints.SET_PASSWORD_ENPOINT, language);
        return axios.post(endPoint, data)
    }
}

export default ResetPasswordRepository.getInstance();