import axios, {AxiosRequestConfig} from "axios";
import LocalStorageAuthManager from "../services/auth/LocalStorageAuthManager";

const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = LocalStorageAuthManager.getInstance().getAuthData().token;
    if (token) {
        config.headers = {
            ...config.headers,
            'Authorization': `Bearer ${token}`,
        };
    }
    return config;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}, (error: any) => {
    return Promise.reject(error);
});

export const axiosRequest = axiosInstance;