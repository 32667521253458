import {useMutation} from "react-query";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {
    TR_HA_OCURRIDO_UN_ERROR,
    TR_LA_CONTRASENA_SE_HA_CAMBIADO_CORRECTAMENTE,
    TR_LA_PETICION_PARA_CAMBIAR_LA_CONTRASENA_HA_CADUCADO
} from "../../../i18n/TR";
import {ResetPasswordRequestData} from "./ws/ResetPasswordRequestDto";
import ResetPasswordRepository from "../domain/ResetPasswordRepository";
import {useHistory} from "react-router";
import {ROUTE_LOGIN} from "../../../routing/Routes";

type ResetPasswordData = {
    loading: boolean,
    fetchResetPassword: (data: ResetPasswordRequestData) => void,
}

const useResetPassword = (): ResetPasswordData => {

    const {t} = useTranslation();
    const history = useHistory();

    const mutationResetPassword = useMutation(
        async (submitData: ResetPasswordRequestData) => ResetPasswordRepository.fetchResetPassword(submitData),
        {
            onError: (error, variables, context) => {
                toast.error(`${t(TR_HA_OCURRIDO_UN_ERROR)}.`);
            },
            onSuccess: (data, variables, context) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (data.data.code === 400) {
                    toast.error(`${t(TR_LA_PETICION_PARA_CAMBIAR_LA_CONTRASENA_HA_CADUCADO)}.`);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                } else if (data.data.code !== 200) {
                    toast.error(`${t(TR_HA_OCURRIDO_UN_ERROR)}.`);
                } else {
                    toast.success(`${t(TR_LA_CONTRASENA_SE_HA_CAMBIADO_CORRECTAMENTE)}`);
                    history.replace(ROUTE_LOGIN)
                }
            },
        }
    );

    return {
        loading: mutationResetPassword.isLoading,
        fetchResetPassword: mutationResetPassword.mutate,
    }
};

export default useResetPassword;