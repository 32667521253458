import {FunctionComponent} from 'react';
import {FormProps} from '../../../../../components/form/FormProps';
import {LoginFormValues} from './LoginFormValues';
import {FormGroup} from 'react-bootstrap';
import useForm, {FormErrors} from '../../../../../components/form/hooks/useForm';
import InputForm from '../../../../../components/form/input/InputForm';
import COButton from '../../../../../components/button/COButton';
import {LoginFormFields} from "./LoginFormFields";
import CheckboxForm from "../../../../../components/form/checkbox/CheckboxForm";
import {TR_CONTRASENA, TR_EMAIL, TR_ENTRAR, TR_MANTENERME_LOGUEADO} from "../../../../../i18n/TR";
import DataMapperBuildError from "../../../../../components/form/exceptions/DataMapperBuildError";
import {UserDataMapper} from "../../../datamapper/UserDataMapper";
import {useTranslation} from "react-i18next";

interface LoginFormProps extends FormProps<LoginFormValues> {
    loading: boolean,
}

const LoginForm: FunctionComponent<LoginFormProps> = (
    {
        onSubmit,
        loading,
    },
) => {
    const {t} = useTranslation();

    const initialValues: Partial<LoginFormValues> = {};

    const handleOnSubmit = (valid: boolean, values: LoginFormValues, errors: FormErrors<LoginFormValues>) => {
        if (valid) {
            onSubmit(values);
        }
    };

    const validate = (data: LoginFormValues): FormErrors<LoginFormValues> => {
        let errors = {} as FormErrors<LoginFormValues>;
        try {
            UserDataMapper.buildFromLoginFormData(data);
        } catch (e) {
            if (e instanceof DataMapperBuildError) {
                errors = e.errors;
            }
        }
        return errors
    };

    const {onField, onSubmitForm, valid} = useForm<LoginFormValues>({
        initialValues,
        validate,
        handleOnSubmit,
    });

    return (
        <form autoComplete="off"
              onSubmit={onSubmitForm}
        >
            <FormGroup className={'floating-label'}>
                <InputForm type={'email'}
                           id={'email'}
                           placeholder={t(TR_EMAIL)}
                           htmlFor={'mail'}
                           {...onField(LoginFormFields.EMAIL)}
                />
            </FormGroup>

            <FormGroup className={'floating-label'}>
                <InputForm type={'password'}
                           className={'password-input'}
                           placeholder={t(TR_CONTRASENA)}
                           htmlFor={'pass'}
                           {...onField(LoginFormFields.PASSWORD)}
                />
            </FormGroup>

            <div className="btn-space-between-center">
                <div className="custom-control custom-checkbox flex-wrap">
                    <CheckboxForm className={'custom-control-input'}
                                  id={'legal'}
                                  htmlFor={'legal'}
                                  name={LoginFormFields.KEEP_LOGIN}
                                  {...onField(LoginFormFields.KEEP_LOGIN)}
                    >
                    </CheckboxForm>
                    <label className={"custom-control-label"}
                           htmlFor={"legal"}>
                        <span>{t(TR_MANTENERME_LOGUEADO)}</span>
                    </label>
                </div>

                <COButton type={'submit'}
                          className="btn-success btn-lg"
                          disabled={!valid}
                          text={t(TR_ENTRAR)}
                />
            </div>
        </form>
    )
};

export default LoginForm;