import {FunctionComponent} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CloseSVG from "../../res/img/close.svg";
import FavouriteDomainModel from "./domain/FavouriteDomainModel";
import COButton from "../../components/button/COButton";
import {TR_FAVORITOS, TR_NO_TIENES_TODAVIA_PANELES_FAVORITOS} from "../../i18n/TR";
import {useTranslation} from "react-i18next";
import LoadingView from "../../components/loading/LoadingView";
import GenerateRoute from "../../routing/GenerateRoute";
import {useHistory} from "react-router";
import {ROUTE_PANEL_DETAIL} from "../../routing/Routes";

interface FavouriteListProps {
    loading: boolean,
    favourites: FavouriteDomainModel[],
    handleDeleteFavourite: (id: string) => void,
}

const FavouriteListView: FunctionComponent<FavouriteListProps> = (
    {
        loading,
        favourites,
        handleDeleteFavourite
    }
    ) => {

        const {t} = useTranslation();
        const history = useHistory();

        const renderFavourites = () => {
            return favourites.map((favourite, index) => {
                const panelId = favourite && favourite.panelId && favourite.panelId.value || '';
                const routeToFavourite = GenerateRoute.createRoute(ROUTE_PANEL_DETAIL, {id: panelId});
                return (
                    <div className="favorite" key={index}>
                        <div className="favorite-img cursor-pointer">
                            <a onClick={() => history.push(routeToFavourite)}>
                                <img src={favourite && favourite.materialPhoto && favourite.materialPhoto.value || ''}
                                     alt="Nombre pieza"/>
                            </a>
                        </div>

                        <div className="favorite-info">
                            <div className="favorite-name cursor-pointer">
                                <a onClick={() => history.push(routeToFavourite)}>
                                    <h3>{favourite && favourite.materialName && favourite.materialName.value || ''}</h3>
                                </a>
                            </div>
                            <div className="favorite-store">
                                <p>{favourite && favourite.shopName && favourite.shopName.value || ''}</p>
                            </div>
                        </div>
                        <div className="favorite-remove">
                            <COButton className={"close"}
                                      variant={'disabled'}
                                      onClick={() => handleDeleteFavourite(favourite.favouriteId!.value)}
                            >
                                <span aria-hidden="true">
                                    <img src={CloseSVG}
                                         alt="Cerrar ventana"
                                    />
                                </span>
                            </COButton>
                        </div>
                    </div>
                )
            })
        };


        if (loading) {
            return <LoadingView loading={loading}/>;
        }

        return (
            <main>
                <Container fluid>
                    <Row className="row justify-content-center">
                        <Col md={10} lg={8} xl={6}>
                            <h2 className="mb-4">{t(TR_FAVORITOS)}</h2>
                            {
                                !favourites || favourites.length === 0 ?
                                <p>{t(TR_NO_TIENES_TODAVIA_PANELES_FAVORITOS)}</p> :
                                renderFavourites()
                            }
                        </Col>
                    </Row>
                </Container>
            </main>
        )
    }
;


export default FavouriteListView;