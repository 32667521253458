import {FormProps} from '../../../../../components/form/FormProps';
import {RegisterFormValues} from './RegisterFormValues';
import {FunctionComponent} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import useForm, {FormErrors} from '../../../../../components/form/hooks/useForm';
import COButton from '../../../../../components/button/COButton';
import {UserDataMapper} from "../../../datamapper/UserDataMapper";
import DataMapperBuildError from "../../../../../components/form/exceptions/DataMapperBuildError";
import FormFields from "./FormFields";
import CheckboxForm from "../../../../../components/form/checkbox/CheckboxForm";
import {RegisterFormFields} from "./RegisterFormFields";
import {Link} from "react-router-dom";
import {ROUTE_LOGIN} from "../../../../../routing/Routes";
import {
    TR_CONDICIONES_DE_USO,
    TR_ENTRAR,
    TR_HE_LEIDO_Y_ACEPTO_LA,
    TR_LOGUEATE,
    TR_POLITICA_DE_PRIVACIDAD,
    TR_REGISTRO,
    TR_Y_LAS,
    TR_YA_TIENES_CUENTA
} from "../../../../../i18n/TR";
import {useTranslation} from "react-i18next";


interface RegisterFormProps extends FormProps<RegisterFormValues> {
    loading: boolean,
    legalLinks: {
        privacyPolicy: string,
        conditionsOfUse: string,
    }
}

const RegisterForm: FunctionComponent<RegisterFormProps> = (
    {
        onSubmit,
        loading,
        legalLinks
    },
) => {

    const {t} = useTranslation();

    const initialValues: Partial<RegisterFormValues> = {};

    const handleOnSubmit = (valid: boolean, values: RegisterFormValues, errors: FormErrors<RegisterFormValues>) => {
        if (valid) {
            onSubmit(values);
        }
    };

    const validate = (data: RegisterFormValues): FormErrors<RegisterFormValues> => {
        let errors = {} as FormErrors<RegisterFormValues>;
        try {
            UserDataMapper.buildFromRegisterFormData(data)
        } catch (e) {
            if (e instanceof DataMapperBuildError) {
                errors = e.errors;
            }
        }
        return errors
    };

    const {onField, onSubmitForm, valid} = useForm<RegisterFormValues>({
        initialValues,
        validate,
        handleOnSubmit,
    });

    return (
        <main id="prueba-bg">
            <Container fluid>
                <Row className={'justify-content-center'}>
                    <Col lg={6}>

                        <h2 className="mb-4">{t(TR_REGISTRO)}</h2>

                        <form autoComplete="off"
                              onSubmit={onSubmitForm}
                        >
                            <FormFields onField={onField}/>

                            <div className="btn-space-between-center">
                                <div className="custom-control custom-checkbox flex-wrap">
                                    <CheckboxForm className={'custom-control-input'}
                                                  id={'legal'}
                                                  htmlFor={'legal'}
                                                  name={RegisterFormFields.ACCEPTED_TERMS}
                                                  {...onField(RegisterFormFields.ACCEPTED_TERMS)}
                                                  error={onField(RegisterFormFields.ACCEPTED_TERMS).error}
                                    >
                                    </CheckboxForm>
                                    <label className={"custom-control-label"}
                                           htmlFor={"legal"}>
                                        <span> {t(TR_HE_LEIDO_Y_ACEPTO_LA)} <a href={legalLinks.privacyPolicy}
                                                                               target="_blank"
                                                                               rel={"noreferrer"}
                                        >
                                            {t(TR_POLITICA_DE_PRIVACIDAD)}</a> {t(TR_Y_LAS)} <a
                                            href={legalLinks.conditionsOfUse}
                                            target="_blank"
                                            rel={"noreferrer"}
                                        > {t(TR_CONDICIONES_DE_USO)} </a>
                                        </span>
                                    </label>
                                </div>

                                <COButton type={'submit'}
                                          className="btn-success btn-lg"
                                          disabled={!valid}
                                          text={t(TR_ENTRAR)}
                                />
                            </div>

                        </form>

                        <div className="mt-3">
                            <Link to={ROUTE_LOGIN}>
                                {t(TR_YA_TIENES_CUENTA)} <strong>{t(TR_LOGUEATE)}</strong>
                            </Link>
                        </div>

                    </Col>

                </Row>
            </Container>

        </main>
    )

};

export default RegisterForm;