import i18next from "../../i18n/I18n";
import {
    TR_CONTRASENA_OBLIGATORIA,
    TR_LA_CONTRASENA_NO_ES_VALIDA_COMPRUEBA_QUE_CONTENGA_MAYUSCULAS_MINUSCULAS_Y_NUMEROS
} from "../../i18n/TR";

export default class Password {

    private readonly _password: string;

    constructor(password: string) {
        if (!password) {
            throw new Error(i18next.t(TR_CONTRASENA_OBLIGATORIA))
        } else if (!this.isValid(password)) {
            throw new Error(i18next.t(TR_LA_CONTRASENA_NO_ES_VALIDA_COMPRUEBA_QUE_CONTENGA_MAYUSCULAS_MINUSCULAS_Y_NUMEROS))
        }

        this._password = password;
    }

    get value(): string {
        return this._password
    }

    isValid(password: string): boolean {
        const regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
        return regPassword.test(password);
    }
}