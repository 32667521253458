import {FunctionComponent} from 'react';
import RegisterView from './RegisterView';
import {RegisterFormValues} from "./form/RegisterFormValues";
import UserDomainModel from "../../domain/UserDomainModel";
import {RegisterRequestData} from "../../infraestructure/ws/RegisterRequestDto";
import {UserDataMapper} from "../../datamapper/UserDataMapper";
import useRegister from "../../infraestructure/useRegister";

const RegisterDataContainer: FunctionComponent = () => {

    const {loading, fetchRegister, legalLinks} = useRegister();

    const handleSubmit = (values: RegisterFormValues) => {
        const user: UserDomainModel = UserDomainModel.fromRegisterFormValues(values);
        const requestData: RegisterRequestData = UserDataMapper.fromRegisterDomainModel(user);
        fetchRegister(requestData);
    };

    return (
        <RegisterView
            loading={loading}
            onSubmit={handleSubmit}
            legalLinks={legalLinks}
        />
    )
};

export default RegisterDataContainer