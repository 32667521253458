import {FormErrors} from "../../../components/form/hooks/useForm";
import DataMapperBuildError from "../../../components/form/exceptions/DataMapperBuildError";
import {ResetPasswordFormValues} from "../screens/reset-password/form/ResetPasswordFormValues";
import PasswordDomainModel from "../domain/PasswordDomainModel";
import {ResetPasswordFormFields} from "../screens/reset-password/form/ResetPasswordFormFields";

export class PasswordDataMapper {

    public static buildFromFormData(data: ResetPasswordFormValues): PasswordDomainModel {
        const password = PasswordDomainModel.buildEmpty();
        const errors = {} as FormErrors<ResetPasswordFormValues>;
        try {
            password.setPassword(data.password)
        } catch (e) {
            errors[ResetPasswordFormFields.PASSWORD] = e.message;
        }

        if (Object.keys(errors).length > 0) {
            throw new DataMapperBuildError(errors);
        }
        return password;
    }
}