import Password from '../../../domain/value-object/Password';
import {ResetPasswordFormValues} from "../screens/reset-password/form/ResetPasswordFormValues";

export default class PasswordDomainModel {

    public static buildEmpty(): PasswordDomainModel {
        return new PasswordDomainModel();
    }

    public static fromFormValues(values: ResetPasswordFormValues): PasswordDomainModel {
        const password = new PasswordDomainModel();
        password.setPassword(values.password);
        return password;
    }

    private _password?: Password;

    private constructor() {
    }

    get password(): Password | undefined {
        return this._password;
    }

    public setPassword(password: string): void {
        this._password = new Password(password);
    }
}