import {useMutation} from "react-query";
import FavouriteListRepository from "../domain/FavouriteListRepository";
import FavouriteDomainModel from "../domain/FavouriteDomainModel";
import PanelRepository from "../../panel/domain/PanelRepository";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {TR_EL_PANEL_SE_HA_ELIMINADO_CORRECTAMENTE_DE_TUS_FAVORITOS, TR_HA_OCURRIDO_UN_ERROR} from "../../../i18n/TR";
import {FavouriteListResponseDto} from "./ws/FavouriteListResponseDto";
import {useEffect} from "react";
import useIsLogged from "../../auth/infraestructure/hooks/useIsLogged";

type FavouriteListData = {
    loading: boolean,
    fetchList: () => void,
    data: FavouriteDomainModel[],
    fetchDeleteFavourite: (panelId: string) => void,
}

const useFavouriteList = (): FavouriteListData => {

    const {t, i18n} = useTranslation();
    const isLogged = useIsLogged();

    useEffect(() => {
        if (isLogged) {
            mutationFavouriteList.mutate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const mutationFavouriteList = useMutation(
        async () => await FavouriteListRepository.fetchFavouriteList(),
        {
            onError: (error, variables, context) => {
                toast.error(t(TR_HA_OCURRIDO_UN_ERROR));
            },
            onSuccess: (data, variables, context) => {

            },
        }
    );

    const mutationDeleteFavourite = useMutation(
        async (panelId: string) => await PanelRepository.fetchDeleteFavourite(panelId),
        {
            onError: (error, variables, context) => {
                toast.error(t(TR_HA_OCURRIDO_UN_ERROR));
            },
            onSuccess: (data, variables, context) => {
                toast.success(t(TR_EL_PANEL_SE_HA_ELIMINADO_CORRECTAMENTE_DE_TUS_FAVORITOS));
                mutationFavouriteList.mutate()
            },
        }
    );

    const favouriteListData: FavouriteListResponseDto[] | undefined = mutationFavouriteList.data?.data.data;
    let favourites: FavouriteDomainModel[] = [];
    if (favouriteListData && favouriteListData.length !== 0) {
        favourites = favouriteListData.map(favouriteData => FavouriteDomainModel.toDomainModel(favouriteData))
    }

    return {
        // Rosa revisar: usamos isSuccess en el loading para mostrar el loader al obtener el listado. Con solo isLoading el
        // loader no se muestra
        loading: mutationFavouriteList.isLoading || (isLogged && !mutationFavouriteList.isSuccess),
        fetchList: mutationFavouriteList.mutate,
        data: favourites,
        fetchDeleteFavourite: mutationDeleteFavourite.mutate,
    }

};

export default useFavouriteList;