import {UseFormReturn} from "../../../../../components/form/hooks/useForm";
import {RegisterFormValues} from "./RegisterFormValues";
import {FunctionComponent} from "react";
import {FormGroup} from "react-bootstrap";
import InputForm from "../../../../../components/form/input/InputForm";
import {RegisterFormFields} from "./RegisterFormFields";
import {TR_APELLIDOS, TR_CONTRASENA, TR_EMAIL, TR_NOMBRE} from "../../../../../i18n/TR";
import {useTranslation} from "react-i18next";


interface FormFieldsProps {
    onField: UseFormReturn<RegisterFormValues>["onField"],
}

const FormFields: FunctionComponent<FormFieldsProps> = (
    {
        onField,
    }) => {

    const {t} = useTranslation();

    return (
        <>
            <FormGroup className={'floating-label'}>
                <InputForm type={'text'}
                           id={'name'}
                           placeholder={t(TR_NOMBRE)}
                           htmlFor={'name'}
                           name={RegisterFormFields.NAME}
                           {...onField(RegisterFormFields.NAME)}
                           error={onField(RegisterFormFields.NAME).error}
                />
            </FormGroup>

            <FormGroup className={'floating-label'}>
                <InputForm type={'text'}
                           id={'surname'}
                           placeholder={t(TR_APELLIDOS)}
                           htmlFor={'surname'}
                           name={RegisterFormFields.LASTNAME}
                           {...onField(RegisterFormFields.LASTNAME)}
                           error={onField(RegisterFormFields.LASTNAME).error}
                />
            </FormGroup>

            <FormGroup className={'floating-label'}>
                <InputForm type={'email'}
                           id={'mail'}
                           placeholder={t(TR_EMAIL)}
                           htmlFor={'mail'}
                           name={RegisterFormFields.EMAIL}
                           {...onField(RegisterFormFields.EMAIL)}
                           error={onField(RegisterFormFields.EMAIL).error}
                />
            </FormGroup>

            <FormGroup className={'floating-label'}>
                <InputForm type={'password'}
                           className={'password-input'}
                           placeholder={t(TR_CONTRASENA)}
                           htmlFor={'pass'}
                           name={RegisterFormFields.PASSWORD}
                           {...onField(RegisterFormFields.PASSWORD)}
                           error={onField(RegisterFormFields.PASSWORD).error}
                />
            </FormGroup>

        </>
    )
};

export default FormFields;