import {FunctionComponent, useReducer} from 'react';
import "./CookiesBanner.scss";
import LocalStorageBannerRepository from "./repository/LocalStorageBannerRepository";
import {useTranslation} from "react-i18next";
import {
    TR_ACEPTAR,
    TR_AQUI, TR_PUEDES_OBTENER_MAS_INFORMACION,
    TR_UTILIZAMOS_COOKIES_PARA_OPTIMIZAR_NUESTRO_SITIO_WEB_Y_NUESTRO_SERVICIO
} from "../../i18n/TR";
import COButton from "../button/COButton";

const CookiesBanner: FunctionComponent = () => {

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const {t} = useTranslation();

    const handleAcceptedCookies = (): void => {
        LocalStorageBannerRepository.getInstance().saveAcceptedCookies(true);
        forceUpdate(); // Volver a renderizar para devolver null
    };


    const acceptedCookies = LocalStorageBannerRepository.getInstance().getAcceptedCookies();

    if (acceptedCookies) {
        return null;
    }

    return (
        <div className={"banner-container"}>

            <div className={"banner-content"}>
                <p className={"text"}>
                    {`${t(TR_UTILIZAMOS_COOKIES_PARA_OPTIMIZAR_NUESTRO_SITIO_WEB_Y_NUESTRO_SERVICIO)}. ${t(TR_PUEDES_OBTENER_MAS_INFORMACION)}`}

                    <a className={"link"}
                       href={'https://www.undefasa.com/politica-de-cookies/'}
                       target={"_blank"}
                       rel={"noreferrer"}
                    >
                        {` ${t(TR_AQUI)}.`}
                    </a>
                </p>

                <COButton className={"btn btn-form-primary"}
                        text={t(TR_ACEPTAR)}
                        onClick={handleAcceptedCookies}

                />
            </div>
        </div>
    )
};


export default CookiesBanner;