import LoginRepository from "../domain/LoginRepository";
import {useMutation} from "react-query";
import {RecoverPasswordRequestData} from "./ws/RecoverPasswordRequestDto";
import LocalStorageAuthManager from "../../../services/auth/LocalStorageAuthManager";
import AuthContext from "./context/AuthContext";
import {useContext} from "react";
import {LoginFormValues} from "../screens/login/form/LoginFormValues";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {
    TR_EL_PANEL_SE_HA_ANADIDO_A_TUS_FAVORITOS_CORRECTAMENTE,
    TR_EL_USUARIO_ES_INVALIDO,
    TR_EL_USUARIO_O_LA_CONTRASENA_SON_INVALIDOS,
    TR_EN_BREVE_RECIBIRAS_UN_CORREO,
    TR_HA_OCURRIDO_UN_ERROR,
    TR_LA_SOLICITUD_SE_HA_REALIZADO_CORRECTAMENTE
} from "../../../i18n/TR";
import PanelRepository from "../../panel/domain/PanelRepository";


type loginData = {
    loading: boolean,
    fetchLogin: (data: LoginFormValues) => void,
    fetchRecoverPassword: (data: RecoverPasswordRequestData) => void,
}

const useLogin = (): loginData => {

    const {setLogged} = useContext(AuthContext);
    const {t} = useTranslation();
    // const history = useHistory();

    const mutationLogin = useMutation(
        async (submitData: LoginFormValues) => LoginRepository.fetchLogin({
            email: submitData.email,
            password: submitData.password,
        }),
        {
            onError: (error, variables, context) => {
                toast.error(`${t(TR_HA_OCURRIDO_UN_ERROR)}.`);
            },
            onSuccess: (data, variables, context) => {
                if (data.data.code !== 200) {
                    toast.error(`${t(TR_EL_USUARIO_O_LA_CONTRASENA_SON_INVALIDOS)}.`);
                } else {
                    const refreshToken = variables.keepLogin ? data.data.refresh_token : '';
                    LocalStorageAuthManager.getInstance().saveAuthData(data.data.data.token, refreshToken);
                    setLogged(true);

                    // Si tras login existe en LS un panel, lo añadimos a favoritos del usuario
                    const panelId = localStorage.getItem('LS_UNDEFASA_PANEL_ID');
                    if (panelId) {
                        PanelRepository.fetchAddFavourite({panel: panelId})
                            .then((data) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                if (data.data.success) {
                                    toast.success(t(TR_EL_PANEL_SE_HA_ANADIDO_A_TUS_FAVORITOS_CORRECTAMENTE));
                                }
                            });
                        localStorage.removeItem('LS_UNDEFASA_PANEL_ID');
                    }
                }
            },
        }
    );

    const mutationRecoverPassword = useMutation(
        async (data: RecoverPasswordRequestData) => LoginRepository.fetchRecoverPassword(data),
        {
            onError: (error, variables, context) => {
                toast.error(`${t(TR_HA_OCURRIDO_UN_ERROR)}.`);
            },
            onSuccess: (data, variables, context) => {
                if (data.data.code !== 200) {
                    toast.error(`${t(TR_EL_USUARIO_ES_INVALIDO)}.`);
                } else {
                    toast.success(`${t(TR_LA_SOLICITUD_SE_HA_REALIZADO_CORRECTAMENTE)}. ${t(TR_EN_BREVE_RECIBIRAS_UN_CORREO)}.`);
                }
            },
        });

    return {
        loading: mutationLogin.isLoading || mutationRecoverPassword.isLoading,
        fetchLogin: mutationLogin.mutate,
        fetchRecoverPassword: mutationRecoverPassword.mutate,
    }
};

export default useLogin;