import i18next from "../../i18n/I18n";
import {TR_CAMPO_OBLIGATORIO} from "../../i18n/TR";

export default class Photo {

    private readonly _photo: string;

    constructor(photo: string) {
        if (!photo) {
            throw new Error(i18next.t(TR_CAMPO_OBLIGATORIO))
        }

        this._photo = photo;
    }

    get value(): string {
        return this._photo
    }
}