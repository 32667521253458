import {FunctionComponent, useState} from 'react';
import LoginForm from "./form/LoginForm";
import {LoginFormValues} from "./form/LoginFormValues";
import {Col, Container, Row} from "react-bootstrap";
import {TR_LOG_IN, TR_NO_TIENES_CUENTA, TR_RECUPERAR_CONTRASENA, TR_REGISTRATE} from "../../../../i18n/TR";
import {ROUTE_REGISTER} from "../../../../routing/Routes";
import {Link} from "react-router-dom";
import RecoverPasswordModal from "./modal/RecoverPasswordModal";
import {useTranslation} from "react-i18next";


interface LoginViewProps {
    loading: boolean,
    onSubmit: (values: LoginFormValues) => void,
    error?: string,
    onRecoverPassword:(email: string) => void,
}

const LoginView: FunctionComponent<LoginViewProps> = (
    {
        loading,
        onSubmit,
        onRecoverPassword
    },
) => {

    const [showRecoverPassword, setShowRecoverPassword] = useState<boolean>(false);
    const {t} = useTranslation();

    const handleForgotPassword = (event: React.MouseEvent) => {
        event.preventDefault();
        setShowRecoverPassword(true)
    };

    const handleRecoverPassword = (email: string) => {
        setShowRecoverPassword(false);
        onRecoverPassword(email);
    };

    return (
        <main>
            <Container fluid>
                <Row className={'justify-content-center'}>
                    <Col lg={6}>

                        <h2 className="mb-4">{t(TR_LOG_IN)}</h2>

                        <LoginForm
                            loading={loading}
                            onSubmit={onSubmit}
                        />

                        <div className="mt-3">
                            <Link to={ROUTE_REGISTER}>
                                {t(TR_NO_TIENES_CUENTA)} <strong>{t(TR_REGISTRATE)}</strong>
                            </Link>
                        </div>
                        <div className="mt-3">
                            <a className={"px-0 cursor-pointer"}
                               onClick={handleForgotPassword}
                            >
                                {t(TR_RECUPERAR_CONTRASENA)}
                            </a>
                        </div>

                    </Col>
                </Row>
            </Container>

            <RecoverPasswordModal onRecoverPassword={handleRecoverPassword}
                                  onClose={() => setShowRecoverPassword(false)}
                                  show={showRecoverPassword}
            />

        </main>

    )
};

export default LoginView;