import Email from '../../../domain/value-object/Email';
import Password from '../../../domain/value-object/Password';
import AcceptedTerms from '../../../domain/value-object/AcceptedTerms';
import {RegisterFormValues} from "../screens/register/form/RegisterFormValues";
import Username from "../../../domain/value-object/Username";
import Lastname from "../../../domain/value-object/Lastname";

export default class UserDomainModel {

    public static buildEmpty(): UserDomainModel {
        return new UserDomainModel();
    }

    public static fromRegisterFormValues(values: RegisterFormValues): UserDomainModel {
        const user = new UserDomainModel();

        user.setName(values.name!);
        user.setLastname(values.lastname!);
        user.setPassword(values.password!);
        user.setEmail(values.email!);
        user.setAcceptTerms(values.acceptedTerms!);

        return user;
    }

    private _username?: Username;
    private _lastname?: Lastname;
    // private _province?: Province;
    private _email?: Email;
    private _password?: Password;
    private _acceptedTerms?: AcceptedTerms;

    private constructor() {
    }

    get username(): Username | undefined {
        return this._username;
    }

    public setName(username: string): void {
        this._username = new Username(username);
    }

    get lastname(): Lastname | undefined {
        return this._lastname;
    }

    public setLastname(lastname: string): void {
        this._lastname = new Lastname(lastname);
    }

    // get province(): Province | undefined {
    //     return this._province;
    // }
    //
    // public setProvince(province: string): void {
    //     this._province = new Province(province);
    // }

    get email(): Email | undefined {
        return this._email;
    }

    public setEmail(email: string): void {
        this._email = new Email(email);
    }

    get password(): Password | undefined {
        return this._password;
    }

    public setPassword(password: string): void {
        this._password = new Password(password);
    }

    get acceptTerms(): AcceptedTerms | undefined {
        return this._acceptedTerms;
    }

    public setAcceptTerms(acceptTerms: boolean): void {
        this._acceptedTerms = new AcceptedTerms(acceptTerms);
    }
}