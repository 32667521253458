import {FavouriteListResponse} from "../infraestructure/ws/FavouriteListResponseDto";
import EndPoint from "../../../task/endpoint/Endpoint";
import {axiosRequest} from "../../../task/axios";
import i18n from "i18next";
import {Endpoints} from "../../../task/endpoint/EndPoints";

class FavouriteListRepository {

    private static INSTANCE = new FavouriteListRepository();

    private constructor() {}

    public static getInstance() {
        return FavouriteListRepository.INSTANCE;
    }

    public async fetchFavouriteList(): Promise<FavouriteListResponse> {
        const endPoint = EndPoint.getEndPoint(Endpoints.FAVOURITE_LIST_ENDPOINT, i18n.language);
        return axiosRequest.get(endPoint);
    }
}

export default FavouriteListRepository.getInstance();