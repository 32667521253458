import {FunctionComponent, useRef, useState} from 'react';
import {FieldProps} from '../FieldProps';
import {StyledProps} from '../../StyledProps';
import classNames from 'classnames';
import './input-form.scss';
import '../label-error.scss';

interface InputFormProps extends FieldProps<string> {
    labelClassName?: string,
    groupClassName?: string,
    size?: number,
    id?: string,
}

type Props = InputFormProps & StyledProps;

const InputForm: FunctionComponent<Props> = (
    {
        className,
        style,
        value,
        onChangeValue,
        onBlur,
        onKeyPress,
        disabled,
        placeholder,
        type,
        label,
        name,
        error,
        htmlFor,
        labelClassName,
        size,
        onClick,
        id,
    },
) => {
    const classes = classNames(
        'form-control',
        {'app-input--disabled': disabled},
        {'app-input--error': error},
        className,
    );

    const iconPasswordRef = useRef(null);
    const [inputType, setInputType] = useState<string>(type as string);

    const handleShowPassword = () => {
        const iconPasswordElement: HTMLElement | null = iconPasswordRef.current;
        const eyeOpenClass = "eye-open";
        const eyeClosedClass = "eye-closed";
        if (iconPasswordElement && inputType === 'password') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            iconPasswordElement.classList.remove(eyeOpenClass);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            iconPasswordElement.classList.add(eyeClosedClass);
            setInputType('string');
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            iconPasswordElement.classList.remove(eyeClosedClass);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            iconPasswordElement.classList.add(eyeOpenClass);
            setInputType('password');
        }
    };

    return (
        <>
            {label &&
            <label htmlFor={htmlFor || 'input'}
                   className={labelClassName}>
                {label}
            </label>}

            <input
                name={name}
                value={value}
                onChange={(event) => onChangeValue && onChangeValue(event.target.value || '')}
                onBlur={(event) => onBlur && onBlur(event.target.value)}
                onKeyPress={(e) => onKeyPress ? onKeyPress(e.key) : null}
                className={classes}
                style={style}
                type={inputType}
                placeholder={placeholder}
                disabled={disabled}
                onClick={onClick}
                size={size}
                id={id}
            />

            {type === 'password' &&
            <button type={'button'}
                      className={'preview-icon'}
            >
                <span ref={iconPasswordRef}
                      className={"eye eye-open"}
                      onClick={handleShowPassword}
                />
            </button>
            }

            {error && <div className={'error__label'}>{error}</div>}

        </>
    );
};

export default InputForm;
