export const TR_HA_OCURRIDO_UN_ERROR = 'TR_HA_OCURRIDO_UN_ERROR';
export const TR_CAMPO_OBLIGATORIO = 'TR_CAMPO_OBLIGATORIO';
export const TR_NOMBRE_OBLIGATORIO = 'TR_NOMBRE_OBLIGATORIO';
export const TR_APELLIDOS_OBLIGATORIO = 'TR_APELLIDOS_OBLIGATORIO';
export const TR_DEBES_ACEPTAR_LOS_TERMINOS = 'TR_DEBES_ACEPTAR_LOS_TERMINOS';
export const TR_CORREO_OBLIGATORIO = 'TR_CORREO_OBLIGATORIO';
export const TR_CONTRASENA_OBLIGATORIA = 'TR_CONTRASENA_OBLIGATORIA';
export const TR_EMAIL = 'TR_EMAIL';
export const TR_E_MAIL = 'TR_E_MAIL';
export const TR_CONTRASENA = 'TR_CONTRASENA';
export const TR_MANTENERME_LOGUEADO = 'TR_MANTENERME_LOGUEADO';
export const TR_ENTRAR = 'TR_ENTRAR';
export const TR_NO_TIENES_CUENTA = 'TR_NO_TIENES_CUENTA';
export const TR_REGISTRATE = 'TR_REGISTRATE';
export const TR_RECUPERAR_CONTRASENA = 'TR_RECUPERAR_CONTRASENA';
export const TR_PANEL = 'TR_PANEL';
export const TR_FAVORITOS = 'TR_FAVORITOS';
export const TR_LOG_OUT = 'TR_LOG_OUT';
export const TR_LOG_IN = 'TR_LOG_IN';
export const TR_CASTELLANO = 'TR_CASTELLANO';
export const TR_ENGLISH = 'TR_ENGLISH';
export const TR_FRANCAIS = 'TR_FRANCAIS';
export const TR_NOMBRE = 'TR_NOMBRE';
export const TR_APELLIDOS = 'TR_APELLIDOS';
export const TR_REGISTRO = 'TR_REGISTRO';
export const TR_HE_LEIDO_Y_ENTIENDO_EL = 'TR_HE_LEIDO_Y_ENTIENDO_EL';
export const TR_AVISO_LEGAL = 'TR_AVISO_LEGAL';
export const TR_YA_TIENES_CUENTA = 'TR_YA_TIENES_CUENTA';
export const TR_LOGUEATE = 'TR_LOGUEATE';
export const TR_INFORMACION = 'TR_INFORMACION';
export const TR_CATALOGO = 'TR_CATALOGO';
export const TR_PDF = 'TR_PDF';
export const TR_DESCARGAR = 'TR_DESCARGAR';
export const TR_CARACTERISTICAS_TECNICAS = 'TR_CARACTERISTICAS_TECNICAS';
export const TR_RECUPERAR = 'TR_RECUPERAR';
export const TR_INTRODUCE_TU_CORREO_ELECTRONICO_Y_ENVIAREMOS_UN_ENLACE = 'TR_INTRODUCE_TU_CORREO_ELECTRONICO_Y_ENVIAREMOS_UN_ENLACE';
export const TR_LA_SOLICITUD_SE_HA_REALIZADO_CORRECTAMENTE = 'TR_LA_SOLICITUD_SE_HA_REALIZADO_CORRECTAMENTE';
export const TR_EN_BREVE_RECIBIRAS_UN_CORREO = 'TR_EN_BREVE_RECIBIRAS_UN_CORREO';
export const TR_TE_HAS_REGISTRADO_CORRECTAMENTE = 'TR_TE_HAS_REGISTRADO_CORRECTAMENTE';
export const TR_EL_PANEL_SE_HA_ELIMINADO_CORRECTAMENTE_DE_TUS_FAVORITOS = 'TR_EL_PANEL_SE_HA_ELIMINADO_CORRECTAMENTE_DE_TUS_FAVORITOS';
export const TR_EL_PANEL_SE_HA_ANADIDO_A_TUS_FAVORITOS_CORRECTAMENTE = 'TR_EL_PANEL_SE_HA_ANADIDO_A_TUS_FAVORITOS_CORRECTAMENTE';
export const TR_LA_CONTRASENA_NO_ES_VALIDA_COMPRUEBA_QUE_CONTENGA_MAYUSCULAS_MINUSCULAS_Y_NUMEROS = 'TR_LA_CONTRASENA_NO_ES_VALIDA_COMPRUEBA_QUE_CONTENGA_MAYUSCULAS_MINUSCULAS_Y_NUMEROS';
export const TR_REPITE_LA_CONTRASENA = 'TR_REPITE_LA_CONTRASENA';
export const TR_CAMBIAR = 'TR_CAMBIAR';
export const TR_LA_CONTRASENA_SE_HA_CAMBIADO_CORRECTAMENTE = 'TR_LA_CONTRASENA_SE_HA_CAMBIADO_CORRECTAMENTE';
export const TR_CAMBIAR_CONTRASENA = 'TR_CAMBIAR_CONTRASENA';
export const TR_LAS_CONTRASENAS_NO_COINCIDEN = 'TR_LAS_CONTRASENAS_NO_COINCIDEN';
export const TR_EL_PANEL_YA_HA_SIDO_ANADIDO_A_FAVORITOS = 'TR_EL_PANEL_YA_HA_SIDO_ANADIDO_A_FAVORITOS';
export const TR_LA_PETICION_PARA_CAMBIAR_LA_CONTRASENA_HA_CADUCADO = 'TR_LA_PETICION_PARA_CAMBIAR_LA_CONTRASENA_HA_CADUCADO';
export const TR_ESPANA = 'TR_ESPANA';
export const TR_TLFNO = 'TR_TLFNO';
export const TR_EL_EMAIL_YA_ESTA_EN_USO = 'TR_EL_EMAIL_YA_ESTA_EN_USO';
export const TR_EL_PANEL_NO_EXISTE = 'TR_EL_PANEL_NO_EXISTE';
export const TR_COLECCION = 'TR_COLECCION';
export const TR_HE_LEIDO_Y_ACEPTO_LA = 'TR_HE_LEIDO_Y_ACEPTO_LA';
export const TR_POLITICA_DE_PRIVACIDAD = 'TR_POLITICA_DE_PRIVACIDAD';
export const TR_Y_LAS = 'TR_Y_LAS';
export const TR_CONDICIONES_DE_USO = 'TR_CONDICIONES_DE_USO';
export const TR_ACEPTAR = 'TR_ACEPTAR';
export const TR_UTILIZAMOS_COOKIES_PARA_OPTIMIZAR_NUESTRO_SITIO_WEB_Y_NUESTRO_SERVICIO = 'TR_UTILIZAMOS_COOKIES_PARA_OPTIMIZAR_NUESTRO_SITIO_WEB_Y_NUESTRO_SERVICIO';
export const TR_PUEDES_OBTENER_MAS_INFORMACION = 'TR_PUEDES_OBTENER_MAS_INFORMACION';
export const TR_AQUI = 'TR_AQUI';
export const TR_EL_USUARIO_O_LA_CONTRASENA_SON_INVALIDOS = 'TR_EL_USUARIO_O_LA_CONTRASENA_SON_INVALIDOS';
export const TR_EL_USUARIO_ES_INVALIDO = 'TR_EL_USUARIO_ES_INVALIDO';
export const TR_NO_TIENES_TODAVIA_PANELES_FAVORITOS = 'TR_NO_TIENES_TODAVIA_PANELES_FAVORITOS';