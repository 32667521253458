import {FunctionComponent} from "react";
import CONavbarScreen from "../navbar/CONavbarScreen";
import COFooter from "../footer/COFooter";
import './AppWrapper.scss'

const AppWrapper: FunctionComponent = (
    {
        children
    }
) => {

    return (
        <body>
            <CONavbarScreen/>

            <div className={"app-wrapper-container "}>
                {children}
            </div>

            <COFooter/>
        </body>
    );
};

export default AppWrapper;