import i18next from "../../i18n/I18n";
import {TR_CAMPO_OBLIGATORIO} from "../../i18n/TR";

export default class Name {

    private readonly _name: string;

    constructor(name: string) {
        if (!name) {
            throw new Error(i18next.t(TR_CAMPO_OBLIGATORIO))
        }

        this._name = name;
    }

    get value(): string {
        return this._name
    }
}