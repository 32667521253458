import {TR_NOMBRE_OBLIGATORIO} from "../../i18n/TR";
import i18next from "../../i18n/I18n";

export default class Username {

    private readonly _username: string;

    constructor(name: string) {
        if (!name) {
            throw new Error(i18next.t(TR_NOMBRE_OBLIGATORIO))
        }

        this._username = name;
    }

    get value(): string {
        return this._username
    }
}