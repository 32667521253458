export default class Description {

    private readonly _description: string;

    constructor(description: string) {
        this._description = description;
    }

    get value(): string {
        return this._description
    }
}