import {FavouriteListResponseDto} from "../infraestructure/ws/FavouriteListResponseDto";
import Id from "../../../domain/value-object/Id";
import Name from "../../../domain/value-object/Name";
import Photo from "../../../domain/value-object/Photo";


export default class FavouriteDomainModel {

    public static buildEmpty(): FavouriteDomainModel {
        return new FavouriteDomainModel();
    }

    public static toDomainModel(values: FavouriteListResponseDto): FavouriteDomainModel {
        const favourite = new FavouriteDomainModel();
        favourite.setFavouriteId(values.id!);
        favourite.setMaterialName(values.nombre_material || '');
        const materialPhoto = values.fotos_material && values.fotos_material.length !== 0 ? values.fotos_material[0] : '';
        favourite.setMaterialPhoto(materialPhoto);
        favourite.setShopName(values.nombre_tienda || '');
        favourite.setPanelId(values.panel_id!);
        return favourite;
    }

    private _favouriteId?: Id;
    private _materialName?: Name;
    private _materialPhoto?: Photo;
    private _shopName?: Name;
    private _panelId?: Id;

    private constructor() {

    }

    get favouriteId(): Id | undefined {
        return this._favouriteId;
    }

    public setFavouriteId(id: string): void {
        this._favouriteId = new Id(id);
    }

    get materialName(): Name | undefined {
        return this._materialName;
    }

    public setMaterialName(name: string): void {
        this._materialName = new Name(name);
    }

    get materialPhoto(): Photo | undefined {
        return this._materialPhoto;
    }

    public setMaterialPhoto(photo: string): void {
        this._materialPhoto = new Photo(photo);
    }

    get shopName(): Name | undefined {
        return this._shopName;
    }

    public setShopName(name: string): void {
        this._shopName = new Name(name);
    }

    get panelId(): Id | undefined {
        return this._panelId;
    }

    public setPanelId(id: string): void {
        this._panelId = new Id(id);
    }
}