export type DownloadableFileType = {
    route: string,
    description: string,
    isTechnicalCharacteristic: boolean,
}

export default class DownloadableFile {

    private readonly _downloadableFile: DownloadableFileType;

    constructor(route: string, description: string, isTechnicalCharacteristic: boolean) {
        this._downloadableFile = {
            route,
            description,
            isTechnicalCharacteristic: isTechnicalCharacteristic
        };
    }

    get value(): DownloadableFileType {
        return this._downloadableFile
    }
}