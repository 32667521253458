import {useMutation, useQuery} from "react-query";
import PanelRepository from "../domain/PanelRepository";
import {PanelCreateRequestData} from "./ws/PanelCreateRequestDto";
import {useTranslation} from "react-i18next";
import {
    TR_EL_PANEL_NO_EXISTE,
    TR_EL_PANEL_SE_HA_ANADIDO_A_TUS_FAVORITOS_CORRECTAMENTE,
    TR_EL_PANEL_YA_HA_SIDO_ANADIDO_A_FAVORITOS,
    TR_HA_OCURRIDO_UN_ERROR
} from "../../../i18n/TR";
import {toast} from "react-toastify";
import PanelDomainModel from "../domain/PanelDomainModel";
import {PanelDetailRequestDto} from "./ws/PanelDetailResponseDto";
import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {ROUTE_LOGIN} from "../../../routing/Routes";

type panelData = {
    loadingPanel: boolean,
    panelData?: PanelDomainModel,
    isError: boolean,
    fetchAddFavourite: (data: PanelCreateRequestData) => void,
    hideAddButton: boolean,
}

const usePanel = (panelId: string): panelData => {

    const {t, i18n} = useTranslation();
    const [hideAddButton, setHideAddButton] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        queryPanelDetail.refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language]);

    const queryPanelDetail = useQuery(
        PanelRepository.USE_QUERY_KEYS.fetchDetail,
        async () => await PanelRepository.fetchDetail(panelId),
        {
            onError: (error) => {
                history.push(ROUTE_LOGIN);
                toast.error(t(TR_EL_PANEL_NO_EXISTE));
            },
            onSuccess: (data) => {
            },
            enabled: true,
            retry: false,
            retryDelay: 60000,
        }
    );

    const mutationAddFavourite = useMutation(
        async (data: PanelCreateRequestData) => await PanelRepository.fetchAddFavourite(data),
        {
            onError: (error, variables, context) => {
                toast.error(t(TR_HA_OCURRIDO_UN_ERROR));
            },
            onSuccess: (data, variables, context) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (data.code === 409) {
                    toast.error(t(TR_EL_PANEL_YA_HA_SIDO_ANADIDO_A_FAVORITOS));
                } else {
                    toast.success(t(TR_EL_PANEL_SE_HA_ANADIDO_A_TUS_FAVORITOS_CORRECTAMENTE));
                    setHideAddButton(true);
                }
            },
        });

    const panelDetailData: PanelDetailRequestDto | undefined = queryPanelDetail.data?.data?.data;
    let panelDetail: PanelDomainModel | undefined;
    if (panelDetailData) {
        panelDetail = PanelDomainModel.toDomainModel(panelDetailData);
    }

    return {
        loadingPanel: queryPanelDetail.isLoading,
        panelData: panelDetail,
        isError: queryPanelDetail.isError,
        fetchAddFavourite: mutationAddFavourite.mutate,
        hideAddButton: hideAddButton,
    }
};

export default usePanel;