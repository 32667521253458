import {RegisterFormValues} from "../screens/register/form/RegisterFormValues";
import UserDomainModel from "../domain/UserDomainModel";
import {FormErrors} from "../../../components/form/hooks/useForm";
import {RegisterFormFields} from "../screens/register/form/RegisterFormFields";
import DataMapperBuildError from "../../../components/form/exceptions/DataMapperBuildError";
import {RegisterRequestData} from "../infraestructure/ws/RegisterRequestDto";
import {LoginFormValues} from "../screens/login/form/LoginFormValues";
import {LoginFormFields} from "../screens/login/form/LoginFormFields";

export class UserDataMapper {

    public static buildFromLoginFormData(data: LoginFormValues): UserDomainModel {
        const user = UserDomainModel.buildEmpty();
        const errors = {} as FormErrors<LoginFormValues>;
        try {
            user.setEmail(data.email)
        } catch (e) {
            errors[LoginFormFields.EMAIL] = e.message;
        }

        try {
           user.setPassword(data.password)
        } catch (e) {
            errors[LoginFormFields.PASSWORD] = e.message;
        }

        if (Object.keys(errors).length > 0) {
            throw new DataMapperBuildError(errors);
        }

        return user;
    }

    public static buildFromRegisterFormData(data: RegisterFormValues): UserDomainModel {
        const user = UserDomainModel.buildEmpty();
        const errors = {} as FormErrors<RegisterFormValues>;

        try {
            user.setName(data.name)
        } catch (e) {
            errors[RegisterFormFields.NAME] = e.message;
        }

        try {
            user.setLastname(data.lastname)
        } catch (e) {
            errors[RegisterFormFields.LASTNAME] = e.message;
        }

        // try {
        //     user.setProvince(data.province)
        // } catch (e) {
        //     errors[RegisterFormFields.PROVINCE] = e.message;
        // }

        try {
            user.setEmail(data.email)
        } catch (e) {
            errors[RegisterFormFields.EMAIL] = e.message;
        }

        try {
            user.setPassword(data.password)
        } catch (e) {
            errors[RegisterFormFields.PASSWORD] = e.message;
        }

        try {
            user.setAcceptTerms(data.acceptedTerms)
        } catch (e) {
            errors[RegisterFormFields.ACCEPTED_TERMS] = e.message;
        }

        if (Object.keys(errors).length > 0) {
            throw new DataMapperBuildError(errors);
        }

        return user;
    }

    public static fromRegisterDomainModel(userDomainModel: UserDomainModel): RegisterRequestData {
        return {
            nombre: userDomainModel.username!.value,
            apellidos: userDomainModel.lastname!.value,
            email: userDomainModel.email!.value,
            password: userDomainModel.password!.value,
            acepta_terminos_y_condiciones: userDomainModel.acceptTerms!.value
        };
    }
}