import {FunctionComponent} from 'react';
import LoginDataContainer from './LoginDataContainer';
import useIsLogged from "../../infraestructure/hooks/useIsLogged";
import LocalStorageAuthManager from "../../../../services/auth/LocalStorageAuthManager";
import {Redirect} from 'react-router';
import {ROUTE_FAVOURITE_LIST} from "../../../../routing/Routes";


const LoginScreen: FunctionComponent = () => {

    const logged: boolean = useIsLogged();
    const token = LocalStorageAuthManager.getInstance().getAuthData().token;
    const hasToken = token && token.length !== 0;

    if (logged && hasToken) {
        return <Redirect to={ROUTE_FAVOURITE_LIST}/>
    }

    return <LoginDataContainer/>
};

export default LoginScreen;