import {createContext} from 'react';

export interface AuthContextData {
    logged: boolean;
    setLogged: (logged: boolean) => void,
}

const AuthContext = createContext<AuthContextData>({
    logged: false,
    setLogged: (logged: boolean) => {},
});

export default AuthContext;
