import {FunctionComponent} from "react";
import {ModalProps} from "react-bootstrap/Modal";
import {Modal, ModalBody} from "react-bootstrap";
import ImgCloseSVG from "../../../res/img/close.svg";

interface PanelImageModalProps extends ModalProps {
    image: string,
}

const PanelImageModal: FunctionComponent<PanelImageModalProps> = (
    {
        image,
        onClose,
        show
    }
) => {



    return (
        <Modal
            show={show}
            onHide={onClose}
            animation={true}
            id={"new-pass"}
            size={'xl'}
            centered
        >
            <Modal.Header>
                <button className={"close"}
                        onClick={onClose}>
                            <span aria-hidden="true">
                                <img src={ImgCloseSVG} alt="Cerrar ventana"/>
                            </span>
                </button>
            </Modal.Header>

            <ModalBody className={"pb-5"}>
                <img src={image} alt="" className="img-fluid"/>
            </ModalBody>
        </Modal>
    );

};

export default PanelImageModal;