import i18next from "../../i18n/I18n";
import {TR_CAMPO_OBLIGATORIO} from "../../i18n/TR";

export default class Id {

    private readonly _id: string;

    constructor(id: string) {
        if (!id) {
            throw new Error(i18next.t(TR_CAMPO_OBLIGATORIO))
        }

        this._id = id;
    }

    get value(): string {
        return this._id
    }
}