import i18n from "i18next";
import translationsEs from './translations/translations.es.json';
import translationsEn from './translations/translations.en.json';
import translationsFr from './translations/translations.fr.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";


export const Languages = {
    en: 'en',
    es: 'es',
    fr: 'fr',
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            [Languages.es]: {
                translation: translationsEs,
            },
            [Languages.en]: {
                translation: translationsEn,
            },
            [Languages.fr]: {
                translation: translationsFr,
            },
        },
        fallbackLng: Languages.en,
        detection: {
            order: ["localStorage", "navigator"],
            lookupQuerystring: "lng",
            lookupLocalStorage: 'LS_UNDEFASA_LNG',
            caches: ["localStorage"]
        },
    });

export default i18n;
