export enum Endpoints {
    // AUTH
    LOGIN_ENDPOINT = '/api/auth/login',
    REGISTER_ENDPOINT = '/api/auth/register',
    RECOVER_PASSWORD_ENDPOINT = '/api/auth/recoverpassword',
    SET_PASSWORD_ENPOINT = '/api/auth/setpassword',
    REFRESH_TOKEN_ENDPOINT = '/api/token/refresh',

    // FAVOURITE
    FAVOURITE_LIST_ENDPOINT = '/api/favourite_list',
    FAVOURITE_CREATE_ENDPOINT = '/api/favourite_new',
    FAVOURITE_DELETE_ENDPOINT = '/api/favourite/:id',

    // PANEL
    PANEL_DETAIL_ENDPOINT = '/api/panel/:id'
}