import {FunctionComponent} from "react";
import PanelView from "./PanelView";
import {useHistory} from "react-router";
import usePanel from "./infraestructure/usePanel";
import {ROUTE_LOGIN} from "../../routing/Routes";
import useFavouriteList from "../favourite/infraestructure/useFavouriteList";
import FavouriteDomainModel from "../favourite/domain/FavouriteDomainModel";
import useIsLogged from "../auth/infraestructure/hooks/useIsLogged";

const PanelDataContainer: FunctionComponent = () => {

    const history = useHistory();
    const panelId = history.location.pathname.split('/')[2];

    const {loadingPanel, panelData, fetchAddFavourite, hideAddButton} = usePanel(panelId);
    const {loading, data} = useFavouriteList();
    const isLogged: boolean = useIsLogged();

    const handleAddFavourites = (): void => {
        if (isLogged) {
            fetchAddFavourite({panel: panelId})
        } else {
            // TODO: ROSA: Revisar Y realizarlo de otra forma
            // Se guarda en LS el id del panel para añadirlo a favoritos tras el login
            localStorage.setItem('LS_UNDEFASA_PANEL_ID', panelId);
            history.push(ROUTE_LOGIN);
        }
    };

    let showAddFavourite = !isLogged;
    if (isLogged) {
        showAddFavourite = data.length === 0 || !data.find((favourite: FavouriteDomainModel) =>
            (favourite && favourite.panelId && parseInt(favourite.panelId.value)) === parseInt(panelId));
    }
    if (hideAddButton) {
        showAddFavourite = false;
    }

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <PanelView panel={panelData}
                   loading={loadingPanel || loading}
                   handleAddFavourites={handleAddFavourites}
                   showAddFavourite={showAddFavourite}
        />
    );
};

export default PanelDataContainer;