import {FunctionComponent} from "react";
import FavouriteListView from "./FavouriteListView";
import useFavouriteList from "./infraestructure/useFavouriteList";

const FavouriteListDataContainer: FunctionComponent = () => {

    const {loading, data, fetchDeleteFavourite} = useFavouriteList();

    const handleDeleteFavourite = (panelId: string): void => {
      fetchDeleteFavourite(panelId);
    };

    return (
        <FavouriteListView loading={loading}
                           favourites={data}
                           handleDeleteFavourite={handleDeleteFavourite}
        />)
};

export default FavouriteListDataContainer;