export default class Qr {

    private readonly _qr: string;

    constructor(qr: string) {
        this._qr = qr;
    }

    get value(): string {
        return this._qr
    }
}