export default class TechnicalCharacteristcs {

    private readonly _technicalCharacteristics: string;

    constructor(technicalCharacteristics: string) {
        this._technicalCharacteristics = technicalCharacteristics;
    }

    get value(): string {
        return this._technicalCharacteristics
    }
}