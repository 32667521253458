import {FormErrors} from "../hooks/useForm";

export default class DataMapperBuildError<T> extends Error {
    private readonly _errors: FormErrors<T>;

    constructor(errors: FormErrors<T>) {
        super('Invalid data to construct object model');
        this._errors = errors;
    }

    get errors(): FormErrors<T> {
        return this._errors;
    }
}
