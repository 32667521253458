import BannerRepository from "./BannerRepository";

class LocalStorageBannerRepository implements BannerRepository {

    private static instance: LocalStorageBannerRepository;

    private LS_COOKIES_BANNER = 'LS_COOKIES_BANNER';

    public static getInstance(): LocalStorageBannerRepository {
        if (!LocalStorageBannerRepository.instance) {
            LocalStorageBannerRepository.instance = new LocalStorageBannerRepository();
        }
        return LocalStorageBannerRepository.instance;
    }

    getAcceptedCookies(): boolean {
        const acceptedCookies = localStorage.getItem(this.LS_COOKIES_BANNER);
        if (acceptedCookies) {
            return JSON.parse(acceptedCookies)
        }
        return false;
    }

    saveAcceptedCookies(accepted: boolean): void {
        localStorage.setItem(this.LS_COOKIES_BANNER, JSON.stringify(accepted));
    }

    clear(): void {
        localStorage.removeItem(this.LS_COOKIES_BANNER);
    }
}

export default LocalStorageBannerRepository;