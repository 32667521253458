import {PanelDetailRequestDto} from "../infraestructure/ws/PanelDetailResponseDto";
import Id from "../../../domain/value-object/Id";
import Qr from "../../../domain/value-object/Qr";
import Url from "../../../domain/value-object/Url";
import Name from "../../../domain/value-object/Name";
import DownloadableFile from "../../../domain/value-object/DownloadableFile";
import TechnicalCharacteristcs from "../../../domain/value-object/TechnicalCharacteristcs";
import Direction from "../../../domain/value-object/Direction";
import Description from "../../../domain/value-object/Description";
import Link from "../../../domain/value-object/Link";
import Route from "../../../domain/value-object/Route";


export default class PanelDomainModel {

    public static buildEmpty(): PanelDomainModel {
        return new PanelDomainModel();
    }

    public static toDomainModel(values: PanelDetailRequestDto): PanelDomainModel {
        const panel = new PanelDomainModel();
        panel.setPanelId(values.panel.id || '');
        panel.setPanelQr(values.panel.qr || '');
        panel.setUrl(values.panel.url || '');
        panel.setPanelName(values.panel.nombre || '');
        panel.setPanelDescription(values.panel.descripcion || '');

        panel.setMaterialId(values.panel.material.id || '');
        panel.setMaterialName(values.panel.material.nombre || '');
        panel.setMaterialGenericQr(values.panel.material.qrgenerico || '');
        panel.setMaterialFiles(values.panel.material.archivos || []);
        panel.setMaterialLinks(values.panel.material.links_externos || []);
        panel.setMaterialPhotos(values.panel.material.fotos || []);
        panel.setMaterialVideos(values.panel.material.videos || []);
        panel.setMaterialTechnicalCharacteristics(values.panel.material.caracteristicasTecnicas);

        panel.setShopId(values.panel.tienda.id || '');
        panel.setShopName(values.panel.tienda.nombre || '');
        panel.setShopDirection(values.panel.tienda.direccion || '');

        return panel;
    }

    private _panelId?: Id;
    private _panelQr?: Qr;
    private _url?: Url;
    private _panelName?: Name;
    private _panelDescription?: Description;

    private _materialId?: Id;
    private _materialName?: Name;
    private _materialGenericQr?: Qr;
    private _materialFiles?: DownloadableFile[];
    private _materialLinks?: Link[];
    private _materialPhotos?: Route[];
    private _materialVideos?: Route[];
    private _materialTechnicalCharacteristics?: TechnicalCharacteristcs;

    private _shopId?: Id;
    private _shopName?: Name;
    private _shopDirection?: Direction;

    private constructor() {

    }

    get panelId(): Id | undefined {
        return this._panelId;
    }

    get panelQr(): Qr | undefined {
        return this._panelQr;
    }

    get panelName(): Name | undefined {
        return this._panelName;
    }

    get panelDescription(): Description | undefined {
        return this._panelDescription;
    }

    get url(): Url | undefined {
        return this._url;
    }

    get materialId(): Id | undefined {
        return this._materialId;
    }

    get materialName(): Name | undefined {
        return this._materialName;
    }

    get materialGenericQr(): Qr | undefined {
        return this._materialGenericQr;
    }

    get materialFiles(): DownloadableFile[] | undefined {
        return this._materialFiles;
    }

    get materialLinks(): Link[] | undefined {
        return this._materialLinks;
    }

    get materialPhotos(): Route[] | undefined {
        return this._materialPhotos;
    }

    get materialVideos(): Route[] | undefined {
        return this._materialVideos;
    }

    get materialTechnicalCharacteristics(): TechnicalCharacteristcs | undefined {
        return this._materialTechnicalCharacteristics;
    }

    get shopId(): Id | undefined {
        return this._shopId;
    }

    get shopName(): Name | undefined {
        return this._shopName;
    }

    get shopDirection(): Direction | undefined {
        return this._shopDirection;
    }

    public setPanelId(id: string): void {
        this._panelId = new Id(id);
    }

    public setPanelQr(qr: string): void {
        this._panelQr = new Qr(qr);
    }

    public setUrl(url: string): void {
        this._url = new Url(url);
    }

    public setPanelName(name: string): void {
        this._panelName = new Name(name);
    }

    public setPanelDescription(description: string): void {
        this._panelDescription = new Description(description);
    }

    public setMaterialId(id: string): void {
        this._materialId = new Id(id);
    }

    public setMaterialName(materialName: string): void {
        this._materialName = new Name(materialName);
    }

    public setMaterialGenericQr(qr: string): void {
        this._materialGenericQr = new Qr(qr);
    }

    public setMaterialFiles(files: [{ruta: string, descripcion: string, esCaracteristicaTecnica: boolean}]): void {
        this._materialFiles = files.map(file => new DownloadableFile(file.ruta, file.descripcion, file.esCaracteristicaTecnica));
    }

    public setMaterialLinks(links: [{ruta: string, descripcion: string}]): void {
        this._materialLinks = links.map(link => new Link(link.ruta, link.descripcion));
    }

    public setMaterialPhotos(photos: string[]): void {
        this._materialPhotos = photos.map(photo => new Route(photo));
    }

    public setMaterialVideos(videos: string[]): void {
        this._materialVideos = videos.map(video => new Route(video));
    }

    public setMaterialTechnicalCharacteristics(characteristics: string): void {
        this._materialTechnicalCharacteristics = new TechnicalCharacteristcs(characteristics);
    }

    public setShopId(id: string): void {
        this._shopId = new Id(id);
    }

    public setShopName(name: string): void {
        this._shopName = new Name(name);
    }

    public setShopDirection(direction: string): void {
        this._shopDirection = new Direction(direction);
    }
}