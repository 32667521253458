export default class Direction {

    private readonly _direction: string;

    constructor(direction: string) {
        this._direction = direction;
    }

    get value(): string {
        return this._direction
    }
}