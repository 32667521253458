import {FunctionComponent} from 'react';
import ResetPasswordForm from "./form/ResetPasswordForm";
import {ResetPasswordFormValues} from "./form/ResetPasswordFormValues";
import {Col, Container, Row} from "react-bootstrap";
import {TR_CAMBIAR_CONTRASENA} from "../../../../i18n/TR";
import {useTranslation} from "react-i18next";


interface LoginViewProps {
    loading: boolean,
    onSubmit: (values: ResetPasswordFormValues) => void,
    error?: string,
}

const ResetPasswordView: FunctionComponent<LoginViewProps> = (
    {
        loading,
        onSubmit,
    },
) => {

    const {t} = useTranslation();

    return (
        <main>
            <Container fluid>
                <Row className={'justify-content-center'}>
                    <Col lg={6}>

                        <h2 className="mb-4">{t(TR_CAMBIAR_CONTRASENA)}</h2>

                        <ResetPasswordForm
                            loading={loading}
                            onSubmit={onSubmit}
                        />

                    </Col>
                </Row>
            </Container>

        </main>

    )
};

export default ResetPasswordView;