import {FunctionComponent} from 'react';
import {RouterProps} from 'react-router';
import {Route, Router} from "react-router-dom";

import {
    ROUTE_FAVOURITE_LIST,
    ROUTE_LOGIN,
    ROUTE_PANEL_DETAIL,
    ROUTE_REGISTER, ROUTE_RESET_PASSWORD,
} from './Routes';
import RegisterScreen from '../modules/auth/screens/register/RegisterScreen';
import AppWrapper from '../components/wrapper/AppWrapper';
import LoginScreen from "../modules/auth/screens/login/LoginScreen";
import PanelScreen from "../modules/panel/PanelScreen";
import FavouriteListScreen from "../modules/favourite/FavouriteListScreen";
import PrivateRoute from "./PrivateRoute";
import Toast from '../components/toast/Toast';
import ResetPasswordScreen from "../modules/auth/screens/reset-password/ResetPasswordScreen";
import CookiesBanner from "../components/cookiesBanner/CookiesBanner";

const Routing: FunctionComponent<RouterProps> = (
    {
        history,
    },
) => {
    return (
        <Router history={history}>
            <AppWrapper>
                <Toast/>
                <Route path={"/"} component={CookiesBanner}/>
                <Route path={ROUTE_LOGIN} exact component={LoginScreen}/>
                <Route path={ROUTE_REGISTER} exact component={RegisterScreen}/>
                <Route path={ROUTE_RESET_PASSWORD} exact component={ResetPasswordScreen}/>
                <Route path={ROUTE_PANEL_DETAIL} exact component={PanelScreen}/>

                <PrivateRoute path={ROUTE_FAVOURITE_LIST} exact component={FavouriteListScreen}/>
                <PrivateRoute path={'/'} exact component={FavouriteListScreen}/>

                {/* Rutas no existentes */}
                {/*<Route path="*" component={LoginScreen}/>*/}
            </AppWrapper>
        </Router>
    );
};

export default Routing;
