import {FunctionComponent, useState} from "react";
import classNames from 'classnames';
import {TR_CARACTERISTICAS_TECNICAS, TR_COLECCION, TR_INFORMACION} from "../../i18n/TR";
import {useTranslation} from "react-i18next";
import LoadingView from "../../components/loading/LoadingView";
import PanelImageModal from "./modal/PanelImageModal";
import './PanelView.scss';
import PanelDomainModel from "./domain/PanelDomainModel";
import DownloadableFile from "../../domain/value-object/DownloadableFile";
import Link from "../../domain/value-object/Link";


import {Swiper, SwiperSlide} from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, {Navigation} from 'swiper';
// install Swiper modules
SwiperCore.use([Navigation]);


interface PanelViewProps {
    panel: PanelDomainModel,
    loading: boolean,
    handleAddFavourites,
    showAddFavourite: boolean,

}

enum Tabs {
    INFO = 'info',
    TECH = 'tech',
}

const PanelView: FunctionComponent<PanelViewProps> = (
    {
        panel,
        loading,
        handleAddFavourites,
        showAddFavourite
    }
) => {

    const [currentTab, setCurrentTab] = useState<string>(Tabs.INFO);
    const {t} = useTranslation();
    const [imageModal, setImageModal] = useState<string>('');
    const [showImageModal, setShowImageModal] = useState<boolean>(false);


    const activeTabClass = (tab: Tabs) => {
        return classNames(
            'nav-link',
            {'cursor-pointer': tab !== currentTab},
            {'active': tab === currentTab}
        )
    };


    const handleClickImage = (image: string): void => {
        setImageModal(image);
        setShowImageModal(true);
    };


    const renderPanelInfo = () => {
        const show = currentTab === Tabs.INFO;
        const classes = classNames(
            'tab-pane',
            'fade',
            {'show': show},
            {'active': show}
        );

        const videoSrc = panel && panel.materialVideos && panel.materialVideos.length !== 0 ? panel.materialVideos[0].value : '';
        const materialFiles = panel && panel.materialFiles && panel.materialFiles.filter(file => !file.value.isTechnicalCharacteristic) || [];

        return (
            <div className={classes}
                 id="info"
                 role="tabpanel"
                 aria-labelledby="info-tab"
            >
                <h2 className="mb-4">{t(TR_INFORMACION)}</h2>
                <div className="product-buttons">
                    {materialFiles.map((file: DownloadableFile, index: number) => {
                        return (
                            <a key={index}
                               href={file.value.route}
                               className="btn btn-outline-dark">
                                {file.value.description}
                            </a>)
                    })
                    }
                </div>
                {videoSrc && videoSrc.length !== 0 &&
                <div className="product-video">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item"
                                src={videoSrc}
                                allowFullScreen/>
                    </div>
                </div>}
                <div className="product-links">
                    {panel.materialLinks && panel.materialLinks.map((link: Link, index) => {
                        return (
                            <>
                                <a key={index} href={link.value.route}>{link.value.description}</a>
                                {panel && panel.materialLinks && (index !== panel.materialLinks.length - 1) &&
                                <span> | </span>}
                            </>)
                    })}
                </div>
            </div>
        )
    };

    const renderTechnicalCharacteristics = () => {
        const show = currentTab === Tabs.TECH;
        const classes = classNames(
            'tab-pane',
            'fade',
            {'show': show},
            {'active': show}
        );

        const chracteristicsText = panel && panel.materialTechnicalCharacteristics ?
            panel.materialTechnicalCharacteristics.value : '';
        const materialFiles = panel && panel.materialFiles && panel.materialFiles.filter(file => file.value.isTechnicalCharacteristic) || [];

        return (
            <div className={classes}
                 id="tech"
                 role="tabpanel"
                 aria-labelledby="tech-tab"
            >
                <h2 className="mb-4">{t(TR_CARACTERISTICAS_TECNICAS)}</h2>

                <div className="product-buttons">
                    {materialFiles.map((file: DownloadableFile, index: number) => {
                        return (
                            <a key={index}
                               href={file.value.route}
                               className="btn btn-outline-dark">
                                {file.value.description}
                            </a>)
                    })
                    }
                </div>

                <p>{chracteristicsText}</p>
            </div>
        )
    };

    const renderAddFavouritesButton = () => {
        return (
            <button type="button"
                    className="fab"
                    onClick={handleAddFavourites}
            >
                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 2v5h5v2H9v5H7V9H2V7h5V2z" fillRule="evenodd"/>
                </svg>
            </button>
        )
    };

    if (loading) {
        return <LoadingView loading={loading}/>;
    }

    const panelDescription = panel && panel.panelDescription ? panel.panelDescription.value : '';

    return (
        <main>
            <div className="container-fluid">
                <div className="row align-items-start">
                    <div className="col-lg-6">

                        <Swiper navigation>
                            {panel && panel.materialPhotos && panel.materialPhotos.map((photo, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <img src={photo.value}
                                             alt=""
                                             onClick={() => handleClickImage(photo.value)}
                                        />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>

                    </div>
                    <div className="col-lg-6">
                        <h1>{panel && panel.panelName ? panel.panelName.value : ''}</h1>
                        <p>{panelDescription}</p>
                        <div className="row">
                            <div className="col">
                                <section className="tabs-section">
                                    <ul className="nav nav-justified">
                                        <li className="nav-item">
                                            <a className={activeTabClass(Tabs.INFO)}
                                               id={"info-tab"}
                                               onClick={() => setCurrentTab(Tabs.INFO)}
                                            >
                                                {t(TR_COLECCION)}
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={activeTabClass(Tabs.TECH)}
                                               id={"info-tab"}
                                               onClick={() => setCurrentTab(Tabs.TECH)}
                                            >
                                                {t(TR_CARACTERISTICAS_TECNICAS)}
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        {renderPanelInfo()}
                                        {renderTechnicalCharacteristics()}
                                        <h3>{panel && panel.shopName ? panel.shopName.value : ''}</h3>
                                        <p>{panel && panel.shopDirection ? panel.shopDirection.value : ''}</p>
                                    </div>

                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showAddFavourite && renderAddFavouritesButton()}

            <PanelImageModal image={imageModal}
                             show={showImageModal}
                             onClose={() => setShowImageModal(false)}
            />

        </main>

    )
};

export default PanelView;