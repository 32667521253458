import {FunctionComponent, useState} from 'react';
import Routing from './routing/Routing';
import {createBrowserHistory, History} from 'history';
import AuthContext from './modules/auth/infraestructure/context/AuthContext';
import {QueryClient, QueryClientProvider} from 'react-query';
import LocalStorageAuthManager from "./services/auth/LocalStorageAuthManager";
import LoginRepository from "./modules/auth/domain/LoginRepository";
import ReactGA from 'react-ga4';
import Config from "./Config";

const App: FunctionComponent = () => {

    const [history] = useState<History>(createBrowserHistory());
    const isLogged = !!LocalStorageAuthManager.getInstance().getAuthData().token;
    const [logged, setLogged] = useState<boolean>(isLogged);
    const queryClient = new QueryClient();
    queryClient.mount();

    ReactGA.initialize(Config.ANALYTICS);
    ReactGA.send("pageview");
    history.listen(() => ReactGA.send("pageview"));

    const day = 24 * 60 * 60 * 1000;
    setInterval(() => {
            if (isLogged) {
                const refreshToken = LocalStorageAuthManager.getInstance().getAuthData().refreshToken;
                if (refreshToken) {
                    LoginRepository.fetchRefreshToken({refresh_token: refreshToken})
                        .then((data) => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            if (data.data.success) {
                                LocalStorageAuthManager.getInstance().saveAuthData(data.data.data.token, data.data.refresh_token)
                            }
                        });
                }
            }
        }, day
    );

    return (
        <QueryClientProvider client={queryClient}>
            <AuthContext.Provider value={{logged: logged, setLogged: setLogged}}>
                <Routing history={history}/>
            </AuthContext.Provider>
        </QueryClientProvider>
    );
};

export default App;
