import {FunctionComponent, ReactNode} from 'react';
import {Redirect, Route} from 'react-router';
import useIsLogged from '../modules/auth/infraestructure/hooks/useIsLogged';
import {ROUTE_LOGIN} from './Routes';
import LocalStorageAuthManager from "../services/auth/LocalStorageAuthManager";

interface PrivateRouteProps {
    path: string;
    component: ReactNode;
    exact?: boolean;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = (
    {
        path,
        component,
        exact,
    },
) => {
    const logged: boolean = useIsLogged();
    const token = LocalStorageAuthManager.getInstance().getAuthData().token;
    const hasToken = token && token.length !== 0;

    if (!logged || !hasToken) {

        return (
            <Route exact path={path}>
                <Redirect to={ROUTE_LOGIN}/>
            </Route>
        )
    }
    return <Route path={path} exact component={component}/>
};

export default PrivateRoute;
