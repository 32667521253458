import {RegisterRequestData} from "../infraestructure/ws/RegisterRequestDto";
import {RegisterResponse} from "../infraestructure/ws/RegisterResponseDto";
import EndPoint from "../../../task/endpoint/Endpoint";
import axios from "axios";
import i18n from "i18next";
import {Endpoints} from "../../../task/endpoint/EndPoints";
import {ResponseRequest} from "../../../task/ResponseRequest";

class RegisterRepository {

    private static INSTANCE = new RegisterRepository();

    private constructor() {
    }

    public static getInstance() {
        return RegisterRepository.INSTANCE;
    }

    public async fetchRegister(data: RegisterRequestData): Promise<ResponseRequest<RegisterResponse>> {
        const endPoint = EndPoint.getEndPoint(Endpoints.REGISTER_ENDPOINT, i18n.language);
        return axios.post(endPoint, data)
    }

}

export default RegisterRepository.getInstance();