class GenerateRoute {

    public static INSTANCE = new GenerateRoute();

    private constructor() {}

    public static getInstance() {
        return GenerateRoute.INSTANCE;
    }

    public createRoute(route: string, params?: { [key: string]: string }): string {
        if (!params)
            return route;

        let routeWithParams = route;

        Object.keys(params).forEach(key => {
            if (key && params[key])
                routeWithParams = routeWithParams.replace(String(':' + key).trim(), String(params[key]).trim());
        });

        return routeWithParams;
    }
}

export default GenerateRoute.getInstance();
