import {FunctionComponent} from 'react';
import LoginView from "./LoginView";
import useLogin from "../../infraestructure/useLogin";


const LoginDataContainer: FunctionComponent = () => {

    const {loading, fetchLogin, fetchRecoverPassword} = useLogin();

    const handleRecoverPassword = (email: string): void => {
        fetchRecoverPassword({email: email})
    };

    return (
        <LoginView
            loading={loading}
            onSubmit={fetchLogin}
            onRecoverPassword={handleRecoverPassword}
        />
    )
};

export default LoginDataContainer