import {FunctionComponent} from 'react';
import RegisterForm from './form/RegisterForm';
import {RegisterFormValues} from "./form/RegisterFormValues";

interface RegisterViewProps {
    loading: boolean,
    onSubmit: (values: RegisterFormValues) => void,
    error?: string,
    legalLinks: {
        privacyPolicy: string,
        conditionsOfUse: string,
    }
}

const RegisterView: FunctionComponent<RegisterViewProps> = (
    {
        loading,
        onSubmit,
        legalLinks
    },
) => {

    return (
        <RegisterForm
            loading={loading}
            onSubmit={onSubmit}
            legalLinks={legalLinks}
        />
    )
};

export default RegisterView;