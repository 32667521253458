import {useMutation} from "react-query";
import RegisterRepository from "../domain/RegisterRepository";
import {RegisterRequestData} from "./ws/RegisterRequestDto";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {
    TR_EL_EMAIL_YA_ESTA_EN_USO,
    TR_HA_OCURRIDO_UN_ERROR,
    TR_TE_HAS_REGISTRADO_CORRECTAMENTE
} from "../../../i18n/TR";
import {useHistory} from "react-router";
import {ROUTE_FAVOURITE_LIST} from "../../../routing/Routes";
import LocalStorageAuthManager from "../../../services/auth/LocalStorageAuthManager";
import {useContext} from "react";
import AuthContext from "./context/AuthContext";
import {Languages} from "../../../i18n/I18n";


type RegisterData = {
    loading: boolean,
    fetchRegister: (data: RegisterRequestData) => void,
    legalLinks: {
        privacyPolicy: string,
        conditionsOfUse: string,
    }
}

const useRegister = (): RegisterData => {

    const {t} = useTranslation();
    const history = useHistory();
    const {setLogged} = useContext(AuthContext);

    const mutationRegister = useMutation(async (data: RegisterRequestData) =>
        await RegisterRepository.fetchRegister(data), {
        onError: (error, variables, context) => {
            toast.error(t(TR_HA_OCURRIDO_UN_ERROR))
        },
        onSuccess: (data, variables, context) => {
            if (data.data.code === 409) {
                toast.error(t(TR_EL_EMAIL_YA_ESTA_EN_USO));
            } else if (data.data.code !== 200) {
                toast.error(t(TR_HA_OCURRIDO_UN_ERROR));
            } else {
                toast.success(t(TR_TE_HAS_REGISTRADO_CORRECTAMENTE));
                LocalStorageAuthManager.getInstance().saveAuthData(data.data.data.token, '');
                setLogged(true);
                history.push(ROUTE_FAVOURITE_LIST);
            }
        },
    });

    function getLegalLinks(): {
        privacyPolicy: string,
        conditionsOfUse: string,
    } {
        if (Languages.es) {
            return {
                privacyPolicy: 'https://www.undefasa.com/politica-de-privacidad/',
                conditionsOfUse: 'https://www.undefasa.com/condiciones-de-uso/'
            }
        }
        if (Languages.fr) {
            return {
                privacyPolicy: 'https://fr.undefasa.com/politique-de-confidentialite/',
                conditionsOfUse: 'https://fr.undefasa.com/condition-dutilisation/'
            }
        }
        return {
            privacyPolicy: 'https://en.undefasa.com/privacy-policy/',
            conditionsOfUse: 'https://en.undefasa.com/conditions-of-use/'
        }
    }

    return {
        loading: mutationRegister.isLoading,
        fetchRegister: mutationRegister.mutate,
        legalLinks: getLegalLinks()
    }
};

export default useRegister;