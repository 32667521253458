import {FunctionComponent, useState} from 'react';
import {FormProps} from '../../../../../components/form/FormProps';
import {ResetPasswordFormValues} from './ResetPasswordFormValues';
import {FormGroup} from 'react-bootstrap';
import useForm, {FormErrors} from '../../../../../components/form/hooks/useForm';
import InputForm from '../../../../../components/form/input/InputForm';
import COButton from '../../../../../components/button/COButton';
import {
    TR_CAMBIAR,
    TR_CONTRASENA,
    TR_LAS_CONTRASENAS_NO_COINCIDEN,
    TR_REPITE_LA_CONTRASENA
} from "../../../../../i18n/TR";
import DataMapperBuildError from "../../../../../components/form/exceptions/DataMapperBuildError";
import {useTranslation} from "react-i18next";
import {PasswordDataMapper} from "../../../datamapper/PasswordDataMapper";
import LoadingView from "../../../../../components/loading/LoadingView";
import {ResetPasswordFormFields} from "./ResetPasswordFormFields";


interface ResetPasswordForm extends FormProps<ResetPasswordFormValues> {
    loading: boolean,
}

const ResetPasswordForm: FunctionComponent<ResetPasswordForm> = (
    {
        onSubmit,
        loading,
    },
) => {
    const {t} = useTranslation();
    const [validRepeatedPassword, setValidRepeatedPassword] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<string>('');
    const [repeatedPasswordError, setRepeatedPasswordError] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rePassword, setRePassword] = useState<string>('');

    const initialValues: Partial<ResetPasswordFormValues> = {};

    const handleOnSubmit = (valid: boolean, values: ResetPasswordFormValues, errors: FormErrors<ResetPasswordFormValues>) => {
        if (valid) {
            onSubmit({password: password});
        }
    };

    const validate = (data: ResetPasswordFormValues): FormErrors<ResetPasswordFormValues> => {
        let errors = {} as FormErrors<ResetPasswordFormValues>;
        try {
            PasswordDataMapper.buildFromFormData({password: password});
        } catch (e) {
            if (e instanceof DataMapperBuildError) {
                errors = e.errors;
            }
        }
        return errors
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {onField, onSubmitForm, valid} = useForm<ResetPasswordFormValues>({
        initialValues,
        validate,
        handleOnSubmit,
    });

    const handleRepeatPassword = (password: string, repeatedPassword: string): void => {
        try {
            PasswordDataMapper.buildFromFormData({password: password});
            setPasswordError('')
        } catch (e) {
            if (e instanceof DataMapperBuildError) {
                setPasswordError(e.errors[ResetPasswordFormFields.PASSWORD]!);
            }
        }

        if (!repeatedPassword) {
            setRepeatedPasswordError(t(TR_REPITE_LA_CONTRASENA));
            setValidRepeatedPassword(false);
        } else if (password !== repeatedPassword) {
            setRepeatedPasswordError(t(TR_LAS_CONTRASENAS_NO_COINCIDEN));
            setValidRepeatedPassword(false);
        } else {
            setRepeatedPasswordError('');
            setValidRepeatedPassword(true);
        }
        setPassword(password);
        setRePassword(repeatedPassword)
    };


    if (loading) {
        return <LoadingView loading={loading}/>
    }

    return (
        <form autoComplete="off"
              onSubmit={onSubmitForm}
        >
            <FormGroup className={'floating-label'}>
                <InputForm type={'password'}
                           className={'password-input'}
                           placeholder={t(TR_CONTRASENA)}
                           htmlFor={'pass'}
                           onChangeValue={(value) => handleRepeatPassword(value, rePassword)}
                           onBlur={(value) => handleRepeatPassword(value, rePassword)}
                           error={passwordError}
                />
            </FormGroup>

            <FormGroup className={'floating-label'}>
                <InputForm type={'password'}
                           className={'password-input'}
                           placeholder={t(TR_REPITE_LA_CONTRASENA)}
                           htmlFor={'pass'}
                           onChangeValue={(value) => handleRepeatPassword(password, value)}
                           onBlur={(value) => handleRepeatPassword(password, value)}
                           error={repeatedPasswordError}
                />
            </FormGroup>

            <div className="btn-space-between-center">

                <COButton type={'submit'}
                          className="btn-success btn-lg"
                          disabled={!validRepeatedPassword || !valid}
                          text={t(TR_CAMBIAR)}
                />
            </div>
        </form>
    )
};

export default ResetPasswordForm;