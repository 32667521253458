export default class Url {

    private readonly _url: string;

    constructor(url: string) {
        this._url = url;
    }

    get value(): string {
        return this._url
    }
}