import {FunctionComponent} from 'react';
import RegisterDataContainer from './RegisterDataContainer';
import useIsLogged from "../../infraestructure/hooks/useIsLogged";
import LocalStorageAuthManager from "../../../../services/auth/LocalStorageAuthManager";
import {ROUTE_FAVOURITE_LIST} from "../../../../routing/Routes";
import {Redirect} from 'react-router';


const RegisterScreen: FunctionComponent = () => {

    const logged: boolean = useIsLogged();
    const token = LocalStorageAuthManager.getInstance().getAuthData().token;
    const hasToken = token && token.length !== 0;

    if (logged && hasToken) {
        return <Redirect to={ROUTE_FAVOURITE_LIST}/>
    }

    return <RegisterDataContainer/>
};

export default RegisterScreen;