import i18next from "../../i18n/I18n";
import {TR_APELLIDOS_OBLIGATORIO} from "../../i18n/TR";

export default class Lastname {

    private readonly _lastname: string;

    constructor(lastname: string) {
        if (!lastname) {
            throw new Error(i18next.t(TR_APELLIDOS_OBLIGATORIO))
        }

        this._lastname = lastname;
    }

    get value(): string {
        return this._lastname
    }
}