import {FunctionComponent, useState} from "react";
import classNames from 'classnames';
import './COMenu.scss';
import {Nav} from "react-bootstrap";
import {Languages} from "../../i18n/I18n";
import {TR_CASTELLANO, TR_ENGLISH, TR_FAVORITOS, TR_FRANCAIS, TR_LOG_IN, TR_LOG_OUT} from "../../i18n/TR";
import {useTranslation} from "react-i18next";
import {ROUTE_FAVOURITE_LIST, ROUTE_LOGIN} from "../../routing/Routes";
import LocalStorageAuthManager from "../../services/auth/LocalStorageAuthManager";
import {useHistory} from "react-router";


const COMenu: FunctionComponent = () => {

    const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false);
    const {i18n, t} = useTranslation();
    const isLogged = !!LocalStorageAuthManager.getInstance().getAuthData().token;
    const history = useHistory();

    const menuClasses = classNames(
        'navbar-collapse',
        'collapse',
        {'show': isMenuExpanded}
    );

    const handleLanguageChange = e => {
        i18n.changeLanguage(e.target.value);
        setIsMenuExpanded(false);
    };

    const getCurrentLanguage = (): string => {
        return i18n.language.split('-')[0].toLowerCase();
    };

    const isSelectedLanguage = (language: string): boolean => {
        return getCurrentLanguage() === language;
    };

    const handleLogOut = (): void => {
        LocalStorageAuthManager.getInstance().clear();
        setIsMenuExpanded(false);
        history.replace(ROUTE_LOGIN)
    };

    const renderNavLink = (title: string, href?: string, handleSelect?: () => void) => {
        return (
            <Nav.Link className={'nav-item'}
                      href={href}
                      onClick={handleSelect}
            >
                {title}
            </Nav.Link>)
    };

    const renderLanguageSelector = () => {
        return (
            <div className="nav-item dropdown">
                <select className={"custom-select select-language cursor-pointer"}
                        name={"language"}
                        id={"language"}
                        value={getCurrentLanguage()}
                        onChange={handleLanguageChange}
                >
                    <option
                        value={Languages.es}
                            selected={isSelectedLanguage(Languages.es)}>
                        {t(TR_CASTELLANO)}
                    </option>
                    <option value={Languages.en}
                            selected={isSelectedLanguage(Languages.en)}>
                        {t(TR_ENGLISH)}
                    </option>
                    <option value={Languages.fr}
                            selected={isSelectedLanguage(Languages.fr)}>
                        {t(TR_FRANCAIS)}
                    </option>
                </select>
            </div>
        )
    };


    return (
        <>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#NavbarToggle"
                aria-controls="NavbarToggle"
                aria-expanded={isMenuExpanded}
                aria-label="Toggle navigation"
                onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            >
                <span className="icon-bar"/>
                <span className="icon-bar"/>
                <span className="icon-bar"/>
            </button>

            <div className={menuClasses}
                 id="NavbarToggle"
                 aria-expanded={isMenuExpanded}
            >
                <div className="navbar-nav">
                    <Nav className="nav-items">
                        {isLogged && renderNavLink(t(TR_FAVORITOS), ROUTE_FAVOURITE_LIST)}
                        {isLogged ?
                            renderNavLink(t(TR_LOG_OUT), undefined, handleLogOut) :
                            renderNavLink(t(TR_LOG_IN), ROUTE_LOGIN)
                        }

                        {renderLanguageSelector()}
                    </Nav>

                </div>
            </div>
        </>
    )

};

export default COMenu;