import Config from "../../Config";


class EndPoint {

    private static INSTANCE = new EndPoint();
    private BASE_URL = Config.BASE_URL;

    public static getInstance() {
        return EndPoint.INSTANCE;
    }

    getEndPoint(endPoint: string, lng: string) {
        const locale = lng.split('-')[0].toLowerCase();
        return this.BASE_URL + '/' + locale + endPoint;
    }
}

export default EndPoint.getInstance();



