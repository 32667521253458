import i18next from "../../i18n/I18n";
import {TR_CORREO_OBLIGATORIO} from "../../i18n/TR";

export default class Email {

    private readonly _email: string;

    constructor(email: string) {
        if (!email) {
            throw new Error(i18next.t(TR_CORREO_OBLIGATORIO))
        }

        this._email = email;
    }

    get value(): string {
        return this._email
    }
}