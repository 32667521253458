export default class Route {

    private readonly _route: string;

    constructor(route: string) {
        this._route = route;
    }

    get value(): string {
        return this._route
    }
}