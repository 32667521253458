import EndPoint from "../../../task/endpoint/Endpoint";
import axios from "axios";
import {PanelDetailResponse} from "../infraestructure/ws/PanelDetailResponseDto";
import GenerateRoute from "../../../routing/GenerateRoute";
import {ResponseRequest} from "../../../task/ResponseRequest";
import {PanelCreateRequestData} from "../infraestructure/ws/PanelCreateRequestDto";
import {axiosRequest} from "../../../task/axios";
import i18n from "i18next";
import {Endpoints} from "../../../task/endpoint/EndPoints";

class PanelRepository {

    private static INSTANCE = new PanelRepository();

    public USE_QUERY_KEYS = {
        fetchDetail: 'fetchDetail'
    };

    private constructor() {}

    public static getInstance() {
        return PanelRepository.INSTANCE;
    }

    public async fetchDetail(panelId: string): Promise<PanelDetailResponse> {
        const endPoint = EndPoint.getEndPoint(Endpoints.PANEL_DETAIL_ENDPOINT, i18n.language);
        const route = GenerateRoute.createRoute(endPoint, {id: panelId});
        return axios.get(route);
    }

    public async fetchAddFavourite(data: PanelCreateRequestData): Promise<ResponseRequest<void>> {
        const endPoint = EndPoint.getEndPoint(Endpoints.FAVOURITE_CREATE_ENDPOINT, i18n.language);
        return axiosRequest.post(endPoint, data);
    }

    public async fetchDeleteFavourite(panelId: string): Promise<ResponseRequest<void>> {
        const endPoint = EndPoint.getEndPoint(Endpoints.FAVOURITE_DELETE_ENDPOINT, i18n.language);
        const route = GenerateRoute.createRoute(endPoint, {id: panelId});
        return axiosRequest.delete(route);
    }
}

export default PanelRepository.getInstance();