import EndPoint from "../../../task/endpoint/Endpoint";
import axios from "axios";
import {LoginRequestData} from "../infraestructure/ws/LoginRequestDto";
import {LoginResponse} from "../infraestructure/ws/LoginResponseDto";
import {RecoverPasswordRequestData} from "../infraestructure/ws/RecoverPasswordRequestDto";
import {ResponseRequest} from "../../../task/ResponseRequest";
import i18n from "i18next";
import {Endpoints} from "../../../task/endpoint/EndPoints";
import {RefreshTokenData} from "../infraestructure/ws/RefreshTokenRequestDto";
import {RefreshTokenResponse} from "../infraestructure/ws/RefreshTokenResponseDto";
import {RecoverPasswordResponseData} from "../infraestructure/ws/RecoverPasswordResponseDto";

class LoginRepository {

    private static INSTANCE = new LoginRepository();

    private constructor() {
    }

    public static getInstance() {
        return LoginRepository.INSTANCE;
    }

    public async fetchLogin(data: LoginRequestData): Promise<LoginResponse> {
        const endPoint = EndPoint.getEndPoint(Endpoints.LOGIN_ENDPOINT, i18n.language);
        return axios.post(endPoint, data)
    }

    public async fetchRecoverPassword(data: RecoverPasswordRequestData): Promise<ResponseRequest<RecoverPasswordResponseData>> {
        const endPoint = EndPoint.getEndPoint(Endpoints.RECOVER_PASSWORD_ENDPOINT, i18n.language);
        return axios.post(endPoint, data)
    }

    public async fetchRefreshToken(data: RefreshTokenData): Promise<RefreshTokenResponse> {
        const endPoint = EndPoint.getEndPoint(Endpoints.REFRESH_TOKEN_ENDPOINT, i18n.language);
        return axios.post(endPoint, data);
    }
}

export default LoginRepository.getInstance();