import i18next from "../../i18n/I18n";
import {TR_DEBES_ACEPTAR_LOS_TERMINOS} from "../../i18n/TR";

export default class AcceptedTerms {

    private readonly _acceptedTerms: boolean;

    constructor(acceptedTerms: boolean) {
        if (!acceptedTerms) {
            throw new Error(i18next.t(TR_DEBES_ACEPTAR_LOS_TERMINOS))
        }

        this._acceptedTerms = acceptedTerms
    }

    get value(): boolean {
        return this._acceptedTerms
    }
}