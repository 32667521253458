import {FunctionComponent} from 'react';
import {FieldProps} from '../FieldProps';
import {StyledProps} from '../../StyledProps';
import classNames from 'classnames';
import './checkbox-form.scss';
import {useTranslation} from "react-i18next";
import '../label-error.scss';

type InputFormTooltip = {
    message: string,
    icon?: string,
}

interface InputFormProps extends FieldProps<string> {
    labelClassName?: string,
    groupClassName?: string,
    size?: number,
    tooltip?: InputFormTooltip,
    readonlyModeText?: boolean,
    id?: string,
}

type Props = InputFormProps & StyledProps;

const CheckboxForm: FunctionComponent<Props> = (
    {
        className,
        style,
        value,
        onChangeValue,
        onBlur,
        disabled,
        label,
        error,
        htmlFor,
        labelClassName,
        size,
        readonlyModeText,
        id,
    },
) => {
    const {t} = useTranslation();

    const classes = classNames(
        {'app-input--error': error},
        className,
    );

    const checked = value ? !!value : false;

    return (
        <>
            {(label || labelClassName) &&
            <label htmlFor={htmlFor || 'input'}
                   className={labelClassName}>
                {label}
            </label>}

            {disabled && readonlyModeText ?
                <span className={'response'}>{t(!value ? 'TR_NO' : 'TR_YES')}</span> :
                <input
                    className={classes}
                    type={'checkbox'}
                    style={style}
                    checked={checked}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={(event) => onChangeValue && onChangeValue(!checked)}
                    onBlur={(event) => onBlur && onBlur(event.target.value)}
                    disabled={disabled}
                    size={size}
                    id={id || 'input'}
                />}
            {
                error && <div className={'error__label'}>{error}</div>
            }
        </>
    );
};

export default CheckboxForm;
