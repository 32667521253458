import {FunctionComponent, useEffect, useState} from "react";
import {ModalProps} from "react-bootstrap/Modal";
import {Col, Container, FormGroup, Modal, ModalBody, Row} from "react-bootstrap";
import {
    TR_EMAIL,
    TR_INTRODUCE_TU_CORREO_ELECTRONICO_Y_ENVIAREMOS_UN_ENLACE,
    TR_RECUPERAR
} from "../../../../../i18n/TR";
import COButton from "../../../../../components/button/COButton";
import ImgClose from "../../../../../res/img/close.svg";
import {useTranslation} from "react-i18next";
import Email from "../../../../../domain/value-object/Email";


interface RecoverPasswordModalProps extends ModalProps {
    onRecoverPassword: (email: string) => void,
}

const RecoverPasswordModal: FunctionComponent<RecoverPasswordModalProps> = (
    {
        onConfirm,
        onClose,
        show,
        onRecoverPassword,
    }
) => {

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const {t} = useTranslation();

    useEffect(() => {
        setEmail('');
        setEmailError(undefined);
    }, [show]);

    const handleValidate = (email) => {
        try {
            new Email(email);
            setEmailError(undefined);
            setEmail(email);
        } catch (e) {
            setEmailError(e.message);
            setEmail('');
        }
    };


    return (
        <Modal
            show={show}
            onHide={onClose}
            animation={true}
            id={"new-pass"}
            size={'xl'}
            centered
        >
            <Modal.Header>
                <button className={"close"}
                        onClick={onClose}>
                            <span aria-hidden="true">
                                <img src={ImgClose} alt="Cerrar ventana"/>
                            </span>
                </button>
            </Modal.Header>

            <ModalBody className={"pb-5"}>
                <Container fluid>

                    <Row className={"justify-content-center"}>
                        <Col md={6}>

                            <p className="text-center mb-5">
                                {t(TR_INTRODUCE_TU_CORREO_ELECTRONICO_Y_ENVIAREMOS_UN_ENLACE)}
                            </p>

                            <FormGroup className={'floating-label flex-row'}>
                                <input
                                    type={'email'}
                                    placeholder={t(TR_EMAIL)}
                                    className={"form-control"}
                                    id={"mail"}
                                    value={email}
                                    onChange={(event) => handleValidate(event.target.value)}
                                    onBlur={(event) => handleValidate(event.target.value)}
                                />
                                <COButton
                                    variant={'success'}
                                    type={'submit'}
                                    onClick={() => onRecoverPassword(email)}
                                    text={t(TR_RECUPERAR)}
                                    disabled={!!emailError || !email}
                                />
                            </FormGroup>
                            {emailError && <div className={'error__label'}>{emailError}</div>}
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </Modal>
    );

};

export default RecoverPasswordModal;