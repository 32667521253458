import {FunctionComponent} from 'react';
import ResetPasswordView from "./ResetPasswordView";
import {useHistory} from "react-router";
import useResetPassword from "../../infraestructure/useResetPassword";
import {ResetPasswordFormValues} from "./form/ResetPasswordFormValues";


const ResetPasswordDataContainer: FunctionComponent = () => {

    const history = useHistory();
    const {loading, fetchResetPassword} = useResetPassword();

    const handleResetPassword = (data: ResetPasswordFormValues): void => {

        const splittedPathname = history.location.pathname.split('/');
        const token = splittedPathname[splittedPathname.length - 1];
        fetchResetPassword({
            password: data.password,
            token: token
        })
    };

    return (
        <ResetPasswordView
            loading={loading}
            onSubmit={handleResetPassword}
        />
    )
};

export default ResetPasswordDataContainer