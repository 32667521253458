import AuthManager from "./AuthManager";
import AuthData from "./AuthData";

class LocalStorageAuthManager implements AuthManager {

    private static instance: LocalStorageAuthManager;

    private LS_TOKEN = 'LS_UNDEFASA_TOKEN';
    private LS_REFRESH_TOKEN = 'LS_UNDEFASA_REFRESH_TOKEN';

    public static getInstance(): LocalStorageAuthManager {
        if (!LocalStorageAuthManager.instance) {
            LocalStorageAuthManager.instance = new LocalStorageAuthManager();
        }
        return LocalStorageAuthManager.instance;
    }

    getAuthData(): AuthData {
        const token =  localStorage.getItem(this.LS_TOKEN);
        const refreshToken = localStorage.getItem(this.LS_REFRESH_TOKEN);

        return {
            token: token || '',
            refreshToken: refreshToken || ''
        }
    }

    saveAuthData(token: string, refreshToken: string): void {
        localStorage.setItem(this.LS_TOKEN, token);
        localStorage.setItem(this.LS_REFRESH_TOKEN, refreshToken);
    }

    clear(): void {
        localStorage.removeItem(this.LS_TOKEN);
        localStorage.removeItem(this.LS_REFRESH_TOKEN);
    }
}

export default LocalStorageAuthManager;