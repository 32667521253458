import {FormEvent, useReducer} from 'react';

export type FormErrors<T> = { [key in keyof Partial<T>]: string | undefined };

export interface UseFormReturn<T> {
    values: T,
    errors: FormErrors<T>,
    onField: (key: keyof T) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChangeValue: (value: any) => void,
        onFocus: () => void,
        onBlur: () => void,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
        error: string | undefined,
    },
    valid: boolean,
    onSubmitForm: (event: FormEvent<HTMLFormElement>) => void,
}

export interface ConfigForm<T> {
    initialValues: Partial<T>,
    validate?: ValidateFunc<T>,
    handleOnSubmit: (valid: boolean, values: T, errors: FormErrors<T>) => void,
}

type Reducer<S, A> = (prevState: S, action: A) => S;

type TouchedKeys = string[];

type StateReducer<T> = {
    values: { [key in keyof T]: T[key] },
    touchedKeys: TouchedKeys,
    submit: boolean,
}

type ActionReducer<T> = {
    type: ActionType,
    key?: keyof T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
}

enum ActionType {
    UPDATE_VALUE,
    TOUCH,
    SUBMIT,
}

function reducer<T>(state: StateReducer<T>, action: ActionReducer<T>): StateReducer<T> {

    switch (action.type) {
        case ActionType.UPDATE_VALUE:
            return {
                ...state,
                values: {
                    ...state.values,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    [action.key!]: action.payload,
                },
            };
        case ActionType.TOUCH:
            if (action.payload) {
                const touchedKeys = state.touchedKeys;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                touchedKeys.push(action.key!.toString());
                return {
                    ...state,
                    //  eslint-disable-next-line
                    //  @ts-ignore
                    touchedKeys: [...new Set(touchedKeys)],
                }
            } else {
                return {
                    ...state,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    touchedKeys: state.touchedKeys.filter((key) => action.key!.toString() === key),
                }
            }
        case ActionType.SUBMIT:
            return {
                ...state,
                submit: action.payload,
            };
        default:
            return state;
    }
}

type ValidateFunc<T> = (data: T) => FormErrors<T>;

function validateForm<T>(state: StateReducer<T>, validate: ValidateFunc<T>): FormErrors<T> {
    return validate(state.values);
}

export default function useForm<T>({initialValues = {}, validate, handleOnSubmit}: ConfigForm<T>): UseFormReturn<T> {

    const [state, dispatch] = useReducer<Reducer<StateReducer<T>, ActionReducer<T>>>(
        reducer,
        {
            values: initialValues as T,
            touchedKeys: [],
            submit: false,
        },
    );

    const onChange = (key: keyof T) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (value: any) => {
            dispatch({
                type: ActionType.UPDATE_VALUE,
                key: key,
                payload: value,
            })
        }
    };

    const onFocus = (key: keyof T) => {
        return () => {
        }
    };

    const onBlur = (key: keyof T) => {
        return () => {
            dispatch({
                type: ActionType.TOUCH,
                key: key,
                payload: true,
            })
        }
    };

    let errors = {} as FormErrors<T>;
    if (validate) {
        errors = validateForm(state, validate);
    }

    const valid = Object.keys(errors).length === 0;

    const onField = (key: keyof T) => {
        return {
            onChangeValue: onChange(key),
            onFocus: onFocus(key),
            onBlur: onBlur(key),
            value: state.values[key],
            error: state.submit || state.touchedKeys.includes(key.toString()) ? errors[key] : undefined,
        }
    };

    const onSubmitForm = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch({
            type: ActionType.SUBMIT,
            payload: true,
        });
        handleOnSubmit(valid, state.values, errors);
    };

    return {
        onField,
        values: state.values,
        errors,
        valid,
        onSubmitForm,
    }
}
